import appConfig from "../configs/appConfig";
import { Lobby } from "../types/lobby";
import User from "../types/user";
import { fetchData } from "../utils/fetch";

interface GetLobbies {
  req: {};
  res: {
    status: "success" | "error";
    data: Lobby[];
  };
}

class LobbiesService {
  async getLobbies({}: GetLobbies["req"]): Promise<GetLobbies["res"]> {
    const res = await fetch(appConfig.apiUrl + `/lobbies`, {
      method: "GET",
    });

    const json = await res.json();

    return json;
  }
}

export default new LobbiesService();
