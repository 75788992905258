import {
  PersonalBlock,
  PersonalInput,
  PersonalInputField,
  PersonalInputFieldWrapper,
  PersonalInputTitle,
  PersonalInputs,
  PersonalTitle,
} from "./PersonalStyles";
import { useNavigate } from "react-router-dom";
import { useWebsocketEventDispatch } from "../../../hooks/useWebsocketEvent";
import Navigation from "../../../components/Navigation/Navigation";
import { useEffect, useState } from "react";
import User from "../../../types/user";
import usersService from "../../../services/usersService";
import ProfileButtons from "../components/ProfileButtons/ProfileButtons";
import { log } from "console";
import authService from "../../../services/authService";
import {getMagic, Magic} from "../../../types/magic";
import MechanicModal from "../Mechanics/MechanicModal/MechanicModal";
import MechanicsChangeModal from "../Mechanics/MechanicsChangeModal/MechanicsChangeModal";
import {
  MechanicsButton,
  MechanicsChosenMechanic,
  MechanicsChosenMechanicBook,
  MechanicsChosenMechanicCheck,
  MechanicsChosenMechanicImage,
  MechanicsChosenMechanics,
  MechanicsChosenMechanicTitle,
  MechanicsList,
  MechanicsListItem, MechanicsListItemBook, MechanicsListItemBookImage,
  MechanicsListItemContent,
  MechanicsListItemImage,
  MechanicsText,
  MechanicsTitle
} from "../Mechanics/MechanicsStyles";

const buttons = [
  {
    image: {
      src: "/images/main.png",
      width: 75.07,
      height: 75,
    },
    text: "Home",
    link: "/profile",
  },

  {
    image: {
      src: "/images/parties.png",
      width: 75,
      height: 75,
    },
    text: "View games",
    link: "/profile/parties",
  },
];

function Personal() {
  const navigate = useNavigate();
  const dispatch = useWebsocketEventDispatch();

  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");

  ///////////////////////////////////////////////////////////////////////////

  const [editOpen, setEditOpen] = useState(false);
  const [magicInfo, setMagicInfo] = useState<Magic | null>(null);

  const [availableMagic, setAvailableMagic] = useState<Magic[]>([Magic.Defend, Magic.Freeze, Magic.SpeedUp]);

  const [magic, setMagic] = useState<Magic[]>([]);

  //////////////////////////////////////////////////////////////////////////

  const getData = async () => {
    const res = await authService.getUser();
    if (res.status === "error") {
      navigate("/auth/registration");
      return;
    }

    setLogin(res.data.login);
    setEmail(res.data.email);
    setMagic([res.data.firstMagic, res.data.secondMagic, res.data.thirdMagic]);
  };

  useEffect(() => {
    getData();
  }, []);

  const changeMagic = async (magic: Magic[]) => {
    const res = await authService.updateUserMagic({
      firstMagic: magic[0],
      secondMagic: magic[1],
      thirdMagic: magic[2],
    });

    if (res.status === "success") {
      getData();
    }
  };

  return (
    <PersonalBlock>


      <PersonalTitle>Personal data</PersonalTitle>

      <PersonalInputs>
        <PersonalInput>
          <PersonalInputTitle>Username</PersonalInputTitle>

          <PersonalInputFieldWrapper>
            <PersonalInputField value={login} readOnly />
          </PersonalInputFieldWrapper>
        </PersonalInput>

        <PersonalInput>
          <PersonalInputTitle>E-mail</PersonalInputTitle>

          <PersonalInputFieldWrapper>
            <PersonalInputField value={email} readOnly/>
          </PersonalInputFieldWrapper>
        </PersonalInput>
      </PersonalInputs>

      {magicInfo && <MechanicModal magic={magicInfo} onClose={() => setMagicInfo(null)} />}
      {editOpen && (
          <MechanicsChangeModal
              magic={magic}
              availableMagic={availableMagic}
              onClose={() => setEditOpen(false)}
              onChange={changeMagic}
          />
      )}

      <MechanicsTitle>Choice of mechanics</MechanicsTitle>

      <MechanicsButton onClick={() => setEditOpen(true)}>In Game</MechanicsButton>

      <MechanicsText>To select other mechanics, click the “In Game” button</MechanicsText>

      <MechanicsChosenMechanics>
        {magic.map((magicItem, idx) => {
          const magicObj = getMagic(magicItem);

          return (
              <MechanicsChosenMechanic key={magicObj.title + idx} onClick={() => setMagicInfo(magicItem)}>
                <MechanicsChosenMechanicImage src={magicObj.image} />
                <MechanicsChosenMechanicTitle>{magicObj.title}</MechanicsChosenMechanicTitle>
                <MechanicsChosenMechanicCheck src="/images/check.png" />
                <MechanicsChosenMechanicBook src="/images/book.png" />
              </MechanicsChosenMechanic>
          );
        })}
      </MechanicsChosenMechanics>

      <MechanicsList>
        {availableMagic.map((magicItem, idx) => {
          const magicObj = getMagic(magicItem);

          return (
              <MechanicsListItem key={magicItem + idx} onClick={() => setMagicInfo(magicItem)}>
                <MechanicsListItemContent>
                  <MechanicsListItemImage src={magicObj.image} />

                  <MechanicsListItemBook>
                    <MechanicsListItemBookImage src="/images/book.png" />
                  </MechanicsListItemBook>
                </MechanicsListItemContent>
              </MechanicsListItem>
          );
        })}
      </MechanicsList>

      <ProfileButtons buttons={buttons} />
      <Navigation />
    </PersonalBlock>



  );
}

export default Personal;
