import React, { FC, PropsWithChildren } from "react";
import appConfig from "../configs/appConfig";

const ws = new WebSocket(appConfig.websocketUrl);

const initialDate = {
  ws,
};

export const websocketContext = React.createContext(initialDate);

const WebSocketProvider: FC<PropsWithChildren> = ({ children }) => {
  return <websocketContext.Provider value={initialDate}>{children}</websocketContext.Provider>;
};

export default WebSocketProvider;
