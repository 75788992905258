import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";

const ProfileButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  gap: ${pxIntoRem(40)};
  padding: 0px ${pxIntoRem(20)};
`;

const ProfileButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${pxIntoRem(400)};
  height: ${pxIntoRem(250)};
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(40)};
  background-color: rgb(248, 243, 227);
  cursor: pointer;
`;

interface IProfileButtonImage {
  width: number;
  height: number;
}

const ProfileButtonImage = styled.img<IProfileButtonImage>`
  width: ${({ width }) => pxIntoRem(width)};
  height: ${({ height }) => pxIntoRem(height)};
`;

const ProfileButtonText = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(30)};
  font-weight: 500;
  line-height: ${pxIntoRem(33)};
  letter-spacing: 0%;
  margin-top: ${pxIntoRem(30)};
`;

export { ProfileButtonsBlock, ProfileButton, ProfileButtonImage, ProfileButtonText };
