import React, { FC, useEffect, useState } from "react";
import {
  MenuLobbiesBlock,
  MenuLobbiesContent,
  MenuLobbiesLogo,
  MenuLobbiesTitle,
  MenuLobbiesHeader,
  MenuLobbiesColumn,
  MenuLobbiesHeaderTitle,
  MenuLobbiesHeaderImage,
  MenuLobbiesItems,
  MenuLobbiesItem,
  MenuLobbiesItemContent,
  MenuLobbiesItemContentText,
  MenuLobbiesItemContentSkills,
  MenuLobbiesItemContentSkill,
  MenuLobbiesItemContentSkillContent,
  MenuLobbiesItemContentTime,
  MenuLobbiesItemContentSkillImage,
  MenuLobbiesButton,
} from "./MenuLobbiesStyles";
import { Lobby } from "../../../types/lobby";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface IMenuLobbies {
  lobbies: Lobby[];
  onCreate(): any;
}

interface UserData {
  [key: string]: any;
}

const MenuLobbies: FC<IMenuLobbies> = ({ onCreate, lobbies }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData>({});

  const toBoard = (lobby: Lobby) => navigate(`/board/${lobby.boardId}`);

  useEffect(() => {
    const fetchUserDataForLobby = async (lobbyId: string) => {
      try {
        const response = await axios.get(`https://api.magic-chess.space/auth/user/${lobbyId}`);
        setUserData(prevState => ({
          ...prevState,
          [response.data.data.id]: response.data // Сохраняем данные пользователя по его ID
        }));
      } catch (error) {
        console.error("Ошибка при получении данных пользователя:", error);
      }
    };

    lobbies.forEach(async (lobby) => {
      await fetchUserDataForLobby(lobby.playerId);
    });
  }, [lobbies]);

  return (
      <MenuLobbiesBlock>
        <MenuLobbiesContent onClick={(e) => e.stopPropagation()}>
          <MenuLobbiesLogo alt="logo" src="/images/logo.png" />
          <MenuLobbiesTitle>Lobby</MenuLobbiesTitle>

          <MenuLobbiesHeader>
            <MenuLobbiesColumn>
              <MenuLobbiesHeaderImage $width={21.93} src="/images/players.svg" />
              <MenuLobbiesHeaderTitle>Player</MenuLobbiesHeaderTitle>
            </MenuLobbiesColumn>

            <MenuLobbiesColumn>
              <MenuLobbiesHeaderImage $width={20.07} src="/images/skills.svg" />
              <MenuLobbiesHeaderTitle>Spells</MenuLobbiesHeaderTitle>
            </MenuLobbiesColumn>

            <MenuLobbiesColumn>
              <MenuLobbiesHeaderImage $width={27.14} src="/images/time.svg" />
              <MenuLobbiesHeaderTitle>Time</MenuLobbiesHeaderTitle>
            </MenuLobbiesColumn>
          </MenuLobbiesHeader>

          {/* Добавляем класс menuLobbiesList */}
          <MenuLobbiesItems className="menuLobbiesList">
            {lobbies.map((lobby) => {
              return (
                  <MenuLobbiesItem onClick={() => toBoard(lobby)}>
                    <MenuLobbiesItemContent>
                      <MenuLobbiesColumn>
                        <MenuLobbiesItemContentText>
                          {userData[lobby.playerId]?.data.login || "Неизвестный игрок"}
                        </MenuLobbiesItemContentText>
                      </MenuLobbiesColumn>

                      <MenuLobbiesColumn>
                        <MenuLobbiesItemContentSkills>
                          <MenuLobbiesItemContentSkill>
                            <MenuLobbiesItemContentSkillContent>
                              <MenuLobbiesItemContentSkillImage alt="skill" src="/images/wind.png" />
                            </MenuLobbiesItemContentSkillContent>
                          </MenuLobbiesItemContentSkill>

                          <MenuLobbiesItemContentSkill>
                            <MenuLobbiesItemContentSkillContent>
                              <MenuLobbiesItemContentSkillImage alt="skill" src="/images/snowflake.png" />
                            </MenuLobbiesItemContentSkillContent>
                          </MenuLobbiesItemContentSkill>

                          <MenuLobbiesItemContentSkill>
                            <MenuLobbiesItemContentSkillContent>
                              <MenuLobbiesItemContentSkillImage alt="skill" src="/images/shield.png" />
                            </MenuLobbiesItemContentSkillContent>
                          </MenuLobbiesItemContentSkill>
                        </MenuLobbiesItemContentSkills>
                      </MenuLobbiesColumn>

                      <MenuLobbiesColumn>
                        <MenuLobbiesItemContentTime>
                          {lobby.initialSeconds}+{lobby.secondsAdding}
                        </MenuLobbiesItemContentTime>
                      </MenuLobbiesColumn>
                    </MenuLobbiesItemContent>
                  </MenuLobbiesItem>
              );
            })}
          </MenuLobbiesItems>

          {/*<MenuLobbiesButton onClick={onCreate}>Создать</MenuLobbiesButton>*/}
        </MenuLobbiesContent>
      </MenuLobbiesBlock>
  );
};

export default MenuLobbies;
