import { Colors } from "./colors";

export enum Figures {
  BISHOP = "bishop",
  KING = "king",
  KNIGHT = "knight",
  PAWN = "pawn",
  QUEEN = "queen",
  ROOK = "rook",
}

interface Figure {
  id: string;
  name: Figures;
  x: number;
  y: number;
  color: Colors;
  hasDoneStep?: boolean;
  isFrozen?: boolean;
  isSpeededUp?: boolean;
  isDefended?: boolean;
}

export type { Figure };
