import appConfig from "../configs/appConfig";
import User from "../types/user";

class UserService {
  getUser(): User | null {
    const localStorageUser = localStorage.getItem("user");
    if (!localStorageUser) {
      return null;
    }

    return JSON.parse(localStorageUser);
  }

  saveUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export default new UserService();
