import {
  MechanicsBlock,
  MechanicsButton,
  MechanicsChosenMechanic,
  MechanicsChosenMechanicBook,
  MechanicsChosenMechanicCheck,
  MechanicsChosenMechanicImage,
  MechanicsChosenMechanicTitle,
  MechanicsChosenMechanics,
  MechanicsList,
  MechanicsListItem,
  MechanicsListItemBook,
  MechanicsListItemBookImage,
  MechanicsListItemContent,
  MechanicsListItemImage,
  MechanicsText,
  MechanicsTitle,
} from "./MechanicsStyles";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation/Navigation";
import ProfileButtons from "../components/ProfileButtons/ProfileButtons";
import { useEffect, useState } from "react";
import authService from "../../../services/authService";
import { Magic, getMagic } from "../../../types/magic";
import MechanicModal from "./MechanicModal/MechanicModal";
import MechanicsChangeModal from "./MechanicsChangeModal/MechanicsChangeModal";

const buttons = [
  {
    image: {
      src: "/images/main.png",
      width: 75.07,
      height: 75,
    },
    text: "Главная",
    link: "/profile",
  },
  {
    image: {
      src: "/images/personal.png",
      width: 75,
      height: 75,
    },
    text: "Личные данные",
    link: "/profile/personal",
  },
  {
    image: {
      src: "/images/parties.png",
      width: 75,
      height: 75,
    },
    text: "Просмотр партий",
    link: "/profile/parties",
  },
];

function Mechanics() {
  const navigate = useNavigate();

  const [editOpen, setEditOpen] = useState(false);
  const [magicInfo, setMagicInfo] = useState<Magic | null>(null);

  const [availableMagic, setAvailableMagic] = useState<Magic[]>([Magic.Defend, Magic.Freeze, Magic.SpeedUp]);

  const [magic, setMagic] = useState<Magic[]>([]);

  const getData = async () => {
    const res = await authService.getUser();
    if (res.status === "error") {
      navigate("/auth/registration");
      return;
    }

    setMagic([res.data.firstMagic, res.data.secondMagic, res.data.thirdMagic]);
  };

  useEffect(() => {
    getData();
  }, []);

  const changeMagic = async (magic: Magic[]) => {
    const res = await authService.updateUserMagic({
      firstMagic: magic[0],
      secondMagic: magic[1],
      thirdMagic: magic[2],
    });

    if (res.status === "success") {
      getData();
    }
  };

  return (
    <MechanicsBlock>


      {magicInfo && <MechanicModal magic={magicInfo} onClose={() => setMagicInfo(null)} />}
      {editOpen && (
        <MechanicsChangeModal
          magic={magic}
          availableMagic={availableMagic}
          onClose={() => setEditOpen(false)}
          onChange={changeMagic}
        />
      )}

      <MechanicsTitle>Choice of mechanics</MechanicsTitle>

      <MechanicsButton onClick={() => setEditOpen(true)}>In game</MechanicsButton>

      <MechanicsText>To select other mechanics, click the “In Game” button</MechanicsText>

      <MechanicsChosenMechanics>
        {magic.map((magicItem, idx) => {
          const magicObj = getMagic(magicItem);

          return (
            <MechanicsChosenMechanic key={magicObj.title + idx} onClick={() => setMagicInfo(magicItem)}>
              <MechanicsChosenMechanicImage src={magicObj.image} />
              <MechanicsChosenMechanicTitle>{magicObj.title}</MechanicsChosenMechanicTitle>
              <MechanicsChosenMechanicCheck src="/images/check.png" />
              <MechanicsChosenMechanicBook src="/images/book.png" />
            </MechanicsChosenMechanic>
          );
        })}
      </MechanicsChosenMechanics>

      <MechanicsList>
        {availableMagic.map((magicItem, idx) => {
          const magicObj = getMagic(magicItem);

          return (
            <MechanicsListItem key={magicItem + idx} onClick={() => setMagicInfo(magicItem)}>
              <MechanicsListItemContent>
                <MechanicsListItemImage src={magicObj.image} />

                <MechanicsListItemBook>
                  <MechanicsListItemBookImage src="/images/book.png" />
                </MechanicsListItemBook>
              </MechanicsListItemContent>
            </MechanicsListItem>
          );
        })}
      </MechanicsList>

      <ProfileButtons buttons={buttons} />
      <Navigation />
    </MechanicsBlock>
  );
}

export default Mechanics;
