import React, { useEffect } from "react";
import { LoginBlock } from "../Login/LoginStyles";
import { useSearchParams } from "react-router-dom";

declare global {
    interface Window {
        Telegram: any;
    }
}


function Referal() {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let startParamValue: string | undefined;
        const ref = searchParams.get("ref") || "";
        const tgWebAppStartParam = searchParams.get("tgWebAppStartParam") || "";
        const referal = tgWebAppStartParam.slice(0, 10);
        const gameId = tgWebAppStartParam.slice(10);
        localStorage.setItem('ref', ref);

        if (window.Telegram && window.Telegram.WebApp) {
            const initData = window.Telegram.WebApp.initData;
            const startParamValue = initData.start_param;
            console.log("Полученное значение startapp:", startParamValue);
            localStorage.setItem('startapp', startParamValue);
        }

        let authUrlWithRef: string;
        if (ref !== undefined && ref !== "") {
            authUrlWithRef = `https://magic-chess.space/auth/referal?id=${encodeURIComponent(gameId)}&ref=${encodeURIComponent(ref)}`;
        } else {
            authUrlWithRef = `https://magic-chess.space/auth/referal?id=${encodeURIComponent(gameId)}&ref=${encodeURIComponent(referal)}`;
        }


        const loadTelegramScript = () => {
            const script = document.createElement('script');
            script.src = "https://telegram.org/js/telegram-widget.js?22";
            script.async = true;
            script.dataset.telegramLogin = "magic_chess_auth_bot";
            script.dataset.size = "large";
            script.dataset.authUrl = authUrlWithRef;
            script.dataset.requestAccess = "write";
            document.body.appendChild(script);
        };

        loadTelegramScript();
    }, [searchParams]);

    return (
        <LoginBlock>
        </LoginBlock>
    );
}

export default Referal;
