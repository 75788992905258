import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const MenuLobbiesBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  margin-top: 2%;
  height: auto;
    @media(max-width: 1000px) {
      margin-top: 20%;
    }

    @media(max-width: 695px) {
    margin-top: 25%;
    }

    @media(max-width: 610px) {
    margin-top: 30%;
    }

    @media(max-width: 550px) {
    margin-top: 35%;
    }

    @media(max-width: 500px) {
      margin-top: 40%;
    }

    @media(max-width: 400px) {
      margin-top: 50%;
    }

    @media(max-width: 320px) {
      margin-top: 60%;
    }
`;

const MenuLobbiesContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 45px 30px 45px;
  border-radius: 15px;
  background: #F8F3E3;
  box-shadow: 0px 4px 4px 0px #00000040;

  @media(max-width: 480px) {
        padding: 20px 25px;
      }

  @media(max-width: 450px) {
      padding: 20px 15px;
    }
`;

const MenuLobbiesLogo = styled.img`
  width: 6rem;
  height: 6rem;
  flex-shrink: 0;
`;

const MenuLobbiesTitle = styled.h2`
  color: #000;
  font-family: "Coda", system-uia;
  text-transform: uppercase;
  font-size: 3.8rem;
  letter-spacing: .5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: ${pxIntoRem(20)};
`;

const MenuLobbiesColumn = styled.div`
  display: flex;
  // width: ${pxIntoRem(162)};
  flex-shrink: 0;
  align-items: stretch;

  &:first-child {
    flex-basis: 65%;
    flex-shrink: 2;
  }
`;

const MenuLobbiesHeader = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${pxIntoRem(35)};
  padding: 0px ${pxIntoRem(38)};
  gap: ${pxIntoRem(18)};
  justify-content: space-between;
`;

interface IMenuLobbiesHeaderImage {
  $width: number;
}

const MenuLobbiesHeaderImage = styled.img<IMenuLobbiesHeaderImage>`
  margin-right: ${pxIntoRem(10)};

  @media(max-width: 500px) {
      width: ${pxIntoRem(30)};
    }
`;

const MenuLobbiesHeaderTitle = styled.h3`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(35)};
  font-weight: 400;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  position: relative;
  top: ${pxIntoRem(8)};
`;

const MenuLobbiesItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(10)};
  margin-top: ${pxIntoRem(25)};
  width: 100%;
  flex-shrink: 0;
  max-height: 87px;
  overflow: auto;
  padding-right: 8px;
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    width: 4px;
}

  &::-webkit-scrollbar-track {
    background-color: darkgrey;
    border-radius: 80px;
}

  &::-webkit-scrollbar-thumb {
    background-color: #858585;
    border-radius: 80px;
}
`;

const MenuLobbiesItem = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #858585;
  border-radius: ${pxIntoRem(30)};
  height: ${pxIntoRem(50)};
  width: 100%;
  padding: ${pxIntoRem(5)};
  width: 100%;
  background: #fff;
`;

const MenuLobbiesItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: ${pxIntoRem(30)};
  padding: 5px ${pxIntoRem(22)};

  ${MenuLobbiesColumn} {
  }
`;

const MenuLobbiesItemContentText = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(35)};
  font-weight: 400;
  line-height: ${pxIntoRem(13)};
  letter-spacing: ${pxIntoRem(1)};
  text-align: left;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    margin-left: ${pxIntoRem(16)};
  }
`;

const MenuLobbiesItemContentSkills = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${pxIntoRem(54)};
  gap: ${pxIntoRem(15)};
`;

const MenuLobbiesItemContentSkill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(30)};
  height: ${pxIntoRem(30)};
  border: ${pxIntoRem(0.5)} solid #858585;
  border-radius: ${pxIntoRem(85)};
  background: rgb(255, 255, 255);
`;

const MenuLobbiesItemContentSkillContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(23)};
  height: ${pxIntoRem(23)};
  border: ${pxIntoRem(0.5)} solid rgb(134, 101, 81);
  border-radius: ${pxIntoRem(77)};
  background: rgb(255, 255, 255);
`;

const MenuLobbiesItemContentSkillImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MenuLobbiesItemContentTime = styled(MenuLobbiesItemContentText)`
  margin-left: ${pxIntoRem(72)}!important;
`;

const MenuLobbiesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 700;
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(30)};
  width: ${pxIntoRem(250)};
  height: ${pxIntoRem(50)};
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid #B18A00;
  background: #FFC700;
`;



export {
  MenuLobbiesBlock,
  MenuLobbiesLogo,
  MenuLobbiesTitle,
  MenuLobbiesContent,
  MenuLobbiesColumn,
  MenuLobbiesHeader,
  MenuLobbiesHeaderImage,
  MenuLobbiesHeaderTitle,
  MenuLobbiesItem,
  MenuLobbiesItemContent,
  MenuLobbiesItems,
  MenuLobbiesItemContentText,
  MenuLobbiesItemContentSkill,
  MenuLobbiesItemContentSkillContent,
  MenuLobbiesItemContentSkills,
  MenuLobbiesItemContentTime,
  MenuLobbiesItemContentSkillImage,
  MenuLobbiesButton,
};
