import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";
import { Colors } from "../../../../types/colors";

interface IBoardFigureTransformBlock {
  left: string;
  bottom: string;
  side: Colors;
}

const BoardFigureTransformBlock = styled.div<IBoardFigureTransformBlock>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${({ left }) => left};
  bottom: ${({ bottom }) => bottom};
  height: 100%;
  width: 100%;
  width: ${pxIntoRem(142)};
  height: ${pxIntoRem(142)};
  background-color: transparent;
  cursor: auto;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  z-index: 999999999999999;
  transform: scale(1.5);

  ${({ side }) => (side === Colors.BLACK ? "transform: rotate(180deg);" : "")}

  @media (max-width: 440px) {
    width: ${pxIntoRem(240)};
    height: ${pxIntoRem(240)};
  }
`;

const BoardFigureTransformContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: ${pxIntoRem(114)};
  border: ${pxIntoRem(1)} solid #ab7047;
  background-color: #F8F3E3;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition-duration: 0s;
`;

const BoardFigureTransformButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${pxIntoRem(45)};
  height: ${pxIntoRem(45)};
  background-color: #ffffff;
  border: ${pxIntoRem(1)} solid #ad7e5c;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;

  position: absolute;

  &:nth-child(n) {
    top: 4%;
    left: 34%;
  }

  &:nth-child(2n) {
    top: 26%;
    left: 62%;
  }

  &:nth-child(3n) {
    top: 59%;
    left: 51%;
    right: auto;
  }

  &:nth-child(4n) {
    top: 59%;
    right: 51%;
    left: auto;
  }

  &:nth-child(5n) {
    top: 26%;
    right: 62%;
    left: auto;
  }

  @media (max-width: 440px) {
    width: ${pxIntoRem(45 * (240 / 142))};
    height: ${pxIntoRem(45 * (240 / 142))};
  }
`;

const BoardFigureTransformButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(40)};
  height: ${pxIntoRem(40)};
  background-color: transparent;
  border-radius: 100%;

  @media (max-width: 440px) {
    width: ${pxIntoRem(40 * (240 / 142))};
    height: ${pxIntoRem(40 * (240 / 142))};
  }
`;

// const BoardControllerButtonDot = styled.div`
//   position: absolute;
//   border-radius: ${pxIntoRem(5)};
//   width: ${pxIntoRem(1)};
//   height: ${pxIntoRem(1)};
//   background-color: #866551;

//   &:first-of-type {
//     left: ${pxIntoRem(11)};
//     top: ${pxIntoRem(43};
//   }

//   &:last-of-type {
//     right: ${pxIntoRem(11)};
//     top: ${pxIntoRem(43};
//   }
// `;

const BoardFigureTransformButtonImage = styled.img`
  width: ${pxIntoRem(18)}!important;

  @media (max-width: 440px) {
    width: ${pxIntoRem(18 * (240 / 142))}!important;
  }
`;

export {
  BoardFigureTransformBlock,
  BoardFigureTransformContent,
  BoardFigureTransformButton,
  BoardFigureTransformButtonContent,
  BoardFigureTransformButtonImage,
};
