import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";

const MechanicModalBlock = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const MechanicModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pxIntoRem(50)};
  border-radius: ${pxIntoRem(100)};
  background-color: rgb(248, 243, 227);
`;

const MechanicModalImage = styled.img`
  height: ${pxIntoRem(80)};
  object-fit: cover;
`;

const MechanicModalTitle = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(20)};
`;

const MechanicModalText = styled.p`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(25)};
  font-weight: 500;
  line-height: ${pxIntoRem(28)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(25)};
`;

const MechanicModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(50)};
  background-color: #FFC700;
  margin-top: ${pxIntoRem(20)};
  width: ${pxIntoRem(200)};
  height: ${pxIntoRem(66)};
  cursor: pointer;
`;

export {
  MechanicModalBlock,
  MechanicModalButton,
  MechanicModalContent,
  MechanicModalImage,
  MechanicModalText,
  MechanicModalTitle,
};
