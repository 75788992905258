import styled from "styled-components";
import { Colors } from "../../../types/colors";
import Player from "../../../types/player";
import pxIntoRem from "../../../utils/pxIntoRem";

const HistoryBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const HistoryBoard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  height: 100%;
  padding: 0px 10px;
`;

interface IHistoryContent {
  side: Colors;
}

const HistoryContent = styled.div<IHistoryContent>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  width: 100%;
  max-width: ${pxIntoRem(560 * 1.1)};
  height: 100%;
  max-height: ${pxIntoRem(560 * 1.1)};
  flex-shrink: 2;

  @media (max-width: 800px) {
    max-width: ${pxIntoRem(560 * 1.5)};
    max-height: ${pxIntoRem(560 * 1.5)};
  }

  @media (max-width: 600px) {
    max-width: ${pxIntoRem(660)};
    max-height: ${pxIntoRem(660)};
  }

  @media (max-width: 520px) {
    max-width: ${pxIntoRem(600)};
    max-height: ${pxIntoRem(600)};
  }

  @media (max-width: 470px) {
    max-width: ${pxIntoRem(500)};
    max-height: ${pxIntoRem(500)};
  }

  @media (max-width: 400px) {
    max-width: ${pxIntoRem(480)};
    max-height: ${pxIntoRem(480)};
  }

  @media (max-width: 300px) {
    max-width: ${pxIntoRem(450)};
    max-height: ${pxIntoRem(450)};
  }
`;

interface IHistoryWinner {
  player: Player | null;
}

const HistoryWinner = styled.div<IHistoryWinner>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999999;

  ${({ player }) => player?.side === Colors.BLACK && `transform: rotate(180deg);`}
`;

const HistoryWinnerTitle = styled.h2`
  color: white;
  font-size: ${pxIntoRem(24)};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
`;

const HistoryWinnerButton = styled.button`
  cursor: pointer;
  font-size: ${pxIntoRem(20)};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  text-decoration: underline;
  background-color: transparent;
  color: white;
`;

interface IHistoryTimer {
  side: Colors;
  player: Player | null;
}

const HistoryTimer = styled.div<IHistoryTimer>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ab7047;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(25)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: ${pxIntoRem(102)};
  height: ${pxIntoRem(52)};
  border: ${pxIntoRem(1)} solid #ab7047;
  position: absolute;

  ${({ side }) => side === Colors.BLACK && `top: ${pxIntoRem(-90)};`}
  ${({ side }) => side === Colors.BLACK && `left: ${pxIntoRem(0)};`}

  ${({ side }) => side === Colors.WHITE && `bottom: ${pxIntoRem(-90)};`}
  ${({ side }) => side === Colors.WHITE && `right: ${pxIntoRem(0)};`}

  ${({ player }) => player?.side === Colors.BLACK && `transform: rotate(180deg);`}
`;

const HistoryNavigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pxIntoRem(67)} ${pxIntoRem(0)};
  width: ${pxIntoRem(360)};
  height: 100vh;
  border-left: ${pxIntoRem(1)} solid #858585;
  background: rgb(248, 243, 227);
`;

const HistoryNavigationTitle = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
`;

const HistoryNavigationButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxIntoRem(10)}; // Расстояние между кнопками уже задано
  margin-top: ${pxIntoRem(20)};
  gap: ${pxIntoRem(50)}; // Добавление отступов справа и слева
`;

const HistoryNavigationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxIntoRem(80)};
  height: ${pxIntoRem(80)};
  background-color: transparent;
  border-radius: 100%;
  background: #FFC700;
`;

const HistoryNavigationButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxIntoRem(70)};
  height: ${pxIntoRem(70)};
  border-radius: 100%;
  background: #FFC700;
`;

interface IHistoryNavigationButtonImage {
  isRight: boolean;
}

const HistoryNavigationButtonImage = styled.img<IHistoryNavigationButtonImage>`
  transform: ${({ isRight }) => (!isRight ? "rotate(180deg)" : "")};
  height: ${pxIntoRem(32)};
`;

const HistoryNavigationSteps = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  padding-left: ${pxIntoRem(20)};
  padding-top: ${pxIntoRem(25)};
  margin-top: ${pxIntoRem(20)};
  gap: ${pxIntoRem(40)};
  gap: ${pxIntoRem(28)};
`;

const HistoryNavigationStep = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: ${pxIntoRem(19)};
  gap: ${pxIntoRem(8)} 0px;
  position: relative;

  div {
    flex-grow: 111;
    width: 100%;
  }
`;

const HistoryNavigationStepText = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(30)};
  font-weight: 700;
  line-height: ${pxIntoRem(33)};
  letter-spacing: 0%;

  &:first-child {
    position: absolute;
    left: 0;
  }
`;

const HistoryNavigationStepMagic = styled.img`
  height: ${pxIntoRem(33)};
  margin: 0px ${pxIntoRem(20)};
  position: relative;
  top: -${pxIntoRem(4)};
`;

const HistoryNavigationStepFigure = styled.img`
  width: ${pxIntoRem(25.46)};
  height: ${pxIntoRem(33)};
  margin-left: ${pxIntoRem(20)};
  margin-right: ${pxIntoRem(20)};
  position: relative;
  top: -${pxIntoRem(7)};
`;

interface IHistoryNavigationStepArrow {
  disableMargin?: boolean;
}

const HistoryNavigationStepArrow = styled.img<IHistoryNavigationStepArrow>`
  height: ${pxIntoRem(15)};
  width: ${pxIntoRem(20.98)};
  ${({ disableMargin }) => !disableMargin && `margin: ${pxIntoRem(0)} ${pxIntoRem(20)};`}
  top: ${({ disableMargin }) => (disableMargin ? pxIntoRem(5) : pxIntoRem(9))};
  position: relative;
`;

const HistoryNavigationStepCheckOrWin = styled.img`
  height: ${pxIntoRem(33)};
  width: ${pxIntoRem(17)};
  object-fit: contain;
  margin-left: ${pxIntoRem(10)};
`;

export {
  HistoryBlock,
  HistoryBoard,
  HistoryContent,
  HistoryWinner,
  HistoryWinnerButton,
  HistoryWinnerTitle,
  HistoryTimer,
  HistoryNavigation,
  HistoryNavigationButton,
  HistoryNavigationButtonContent,
  HistoryNavigationButtons,
  HistoryNavigationTitle,
  HistoryNavigationButtonImage,
  HistoryNavigationSteps,
  HistoryNavigationStep,
  HistoryNavigationStepText,
  HistoryNavigationStepArrow,
  HistoryNavigationStepFigure,
  HistoryNavigationStepMagic,
  HistoryNavigationStepCheckOrWin,
};
