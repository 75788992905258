import React, { useEffect, useState } from "react";
import { MenuBlock, MenuBoard, MenuBoardFrame, MenuBoardImage, MenuButtonCreate, MenuButtonFriend, MenuButtons } from "./MenuStyles";
import { useNavigate } from "react-router-dom";
import useWebsocketEvent, { useWebsocketEventDispatch } from "../../hooks/useWebsocketEvent";
import { ClientEvent, ServerEvent } from "../../types/events";
import { Colors } from "../../types/colors";
import MenuSettings from "./MenuSettings/MenuSettings";
import Navigation from "../../components/Navigation/Navigation";
import MenuLobbies from "./MenuLobbies/MenuLobbies";
import lobbiesService from "../../services/lobbiesService";
import { Lobby } from "../../types/lobby";
import MenuPrivateLink from "./MenuPrivateLink/MenuPrivateLink";
import ProfileHeader from "../../components/Profile Header/ProfileHeader";

function Menu() {
  const navigate = useNavigate();
  const dispatch = useWebsocketEventDispatch();

  const [privateBoardId, setPrivateBoardId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState<"public" | "private" | null>(null);
  const [lobbies, setLobbies] = useState<Lobby[]>([]);

  useWebsocketEvent(ClientEvent.BoardCreate, (data: any) => {
    const { boardId } = data;
    setPrivateBoardId(boardId);
  });

  useEffect(() => {
    if (type === "public" && privateBoardId !== null) {
      navigate(`/board/${privateBoardId}`, {
        state: {
          isReady: true,
        },
      });
    }
  }, [privateBoardId, type]);

  async function getLobbies() {
    const res = await lobbiesService.getLobbies({});
    if (res.status === "success") {
      setLobbies(res.data);
    }
  }

  async function onModalOpen(type: "public" | "private") {
    if (type === "public") {
      await getLobbies();
    }

    setType(type);
    setIsModalOpen(true);
  }

  function onModalClose() {
    setType(null);
    setIsModalOpen(false);
    setPrivateBoardId(null);
  }

  async function onCreate(initialSeconds: number, secondsAdding: number) {
    dispatch(ServerEvent.BoardCreate, {
      side: Colors.WHITE,
      type,
      initialSeconds,
      secondsAdding,
    });
  }

  useEffect(() => {
    getLobbies(); // Initial fetch

    const intervalId = setInterval(() => {
      getLobbies(); // Fetch every second
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
      <MenuBlock>
        <ProfileHeader />
        {privateBoardId === null && (
            <MenuLobbies lobbies={lobbies} onCreate={() => setLobbies([])} />
        )}

        {isModalOpen && privateBoardId === null && (
            <MenuSettings onClick={onCreate} onClose={onModalClose} />
        )}

        {isModalOpen && privateBoardId !== null && type === "private" && (
            <MenuPrivateLink onClose={onModalClose} boardId={privateBoardId} />
        )}

        <MenuButtons>
          <MenuButtonCreate onClick={() => onModalOpen("public")}>CREATE GAME</MenuButtonCreate>
          <MenuButtonFriend onClick={() => onModalOpen("private")}>PLAY WITH FRIEND</MenuButtonFriend>
        </MenuButtons>

        <Navigation />
      </MenuBlock>
  );
}

export default Menu;