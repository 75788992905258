import React, { FC, useMemo, useState, useEffect } from "react";
import {
  BoardEndBlock,
  BoardEndButton,
  BoardEndButtons,
  BoardEndContent,
  BoardEndLogo,
  BoardEndTitle,
} from "./BoardEndStyles";
import { useNavigate } from "react-router-dom";
import Player from "../../../types/player";
import authService from "../../../services/authService";

interface IBoardEnd {
  result: "win" | "lose" | "between";
  player: Player | null;
  onClick(): void;
}

const BoardEnd: FC<IBoardEnd> = ({ result, player, onClick }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [bonusMessage, setBonusMessage] = useState<string>("");

  useEffect(() => {
    const fetchBonus = async () => {
      if (result === "win") {
        const res = await authService.getUser();
        if (res.status === "success") {
          const newBonus = res.data.bonus + 10;
          await authService.updateUserBonus({
            id: res.data.id,
            bonus: newBonus,
          });
          setBonusMessage(`+10 bonuses`); // Устанавливаем сообщение о бонусах
        }
        setTitle("You win!");
      } else if (result === "lose") {
        setTitle("You lose(");
        setBonusMessage(""); // Сбрасываем сообщение о бонусах
      } else {
        setTitle("Draw!");
        setBonusMessage(""); // Сбрасываем сообщение о бонусах
      }
    };

    fetchBonus();
  }, [result]);

  const toMenu = () => navigate("/menu");

  return (
      <BoardEndBlock>
        <BoardEndContent player={player}>
          <BoardEndLogo alt="logo" src="/images/logo.png" />
          <BoardEndTitle>{title}</BoardEndTitle>
          {bonusMessage && <div>{bonusMessage}</div>}
          <BoardEndButtons>
            <BoardEndButton onClick={toMenu}>Return to lobby</BoardEndButton>
            <BoardEndButton onClick={onClick}>View Batch</BoardEndButton>
          </BoardEndButtons>
        </BoardEndContent>
      </BoardEndBlock>
  );
};

export { BoardEnd };