import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const MenuSettingsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const MenuSettingsContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${pxIntoRem(800)};
  padding: ${pxIntoRem(35)} ${pxIntoRem(150)} ${pxIntoRem(136)} ${pxIntoRem(150)};
  border-radius: ${pxIntoRem(140)};
  background: #F8F3E3;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const MenuSettingsLogo = styled.img`
  width: ${pxIntoRem(78.9)};
  height: ${pxIntoRem(80)};
  flex-shrink: 0;
`;

const MenuSettingsTitle = styled.h2`
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: ${pxIntoRem(20)};
`;

const MenuSettingsContentItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(40)};
  border-radius: ${pxIntoRem(50)};
  margin-top: ${pxIntoRem(94)};
  width: ${pxIntoRem(464)};
  flex-shrink: 0;
  padding: ${pxIntoRem(40)} ${pxIntoRem(0)} ${pxIntoRem(80)} ${pxIntoRem(0)};
  transform: scale(1.3);
`;

const MenuSettingsContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const MenuSettingsContentItemTitle = styled.span`
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    color: #000;
    font-family: "Coda", system-uia;
    font-size: ${pxIntoRem(20)};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

// const MenuSettingsContentItemRange = styled.div`
//   position: absolute;
//   bottom: ${pxIntoRem(0)};
//   height: ${pxIntoRem(1)};
//   width: 99.5%;
//   // background-color: #f6c944;
// `;

// const MenuSettingsContentItemRangeProgress = styled.div`
//   position: absolute;
//   bottom: -${pxIntoRem(1)};
//   height: 1.${pxIntoRem(5)};
//   width: 100%;
// //   background-color: #f6c944;
// `;

const MenuSettingsContentItemRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pxIntoRem(30)};
  width: ${pxIntoRem(390)};
  height: ${pxIntoRem(40)};
  position: relative;
  margin-top: ${pxIntoRem(10)};

  &::after {
    content: "";
    position: absolute;
    width: 102%;
    height: 120%;
    border: ${pxIntoRem(1)} solid #858585;
    border-radius: ${pxIntoRem(70)};
    pointer-events: none;
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 99%;
  //   height: 95%;
  //   border: ${pxIntoRem(1)} solid #858585;
  //   border-radius: ${pxIntoRem(70)};
  //   pointer-events: none;
  // }
`;

const MenuSettingsContentItemRangeInput = styled.input`
  display: block;
  width: 100%;
  height: ${pxIntoRem(1)};
  background: none;
  position: absolute;
  padding: ${pxIntoRem(0)} ${pxIntoRem(8)};
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  pointer-events: none;

  &::-webkit-slider-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    width: ${pxIntoRem(50)};
    height: ${pxIntoRem(30)};
    flex-shrink: 0;
    border-radius: ${pxIntoRem(30)};
    background: #858585;
    cursor: pointer;
    border: ${pxIntoRem(1)} solid #858585;
  }

  &::-moz-range-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    width: ${pxIntoRem(40)};
    height: ${pxIntoRem(30)};
    flex-shrink: 0;
    border-radius: ${pxIntoRem(30)};
    background-color: #858585;
    cursor: pointer;
    border: ${pxIntoRem(1)} solid #ad7e5c;
  }

  &::-ms-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    width: ${pxIntoRem(40)};
    height: ${pxIntoRem(30)};
    flex-shrink: 0;
    border-radius: ${pxIntoRem(30)};
    background-color: #ad7e5c;
    cursor: pointer;
    border: ${pxIntoRem(1)} solid #ad7e5c;
  }
`;

const MenuSettingsContentItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21.250rem;
  height: 4.375rem; 
  position: relative;
  background-color: transparent;
  margin-top: ${pxIntoRem(10)};
  cursor: pointer;
  background: #FFC700;
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 50px;
`;

export {
  MenuSettingsBlock,
  MenuSettingsContentItems,
  MenuSettingsContentItemTitle,
  MenuSettingsLogo,
  MenuSettingsTitle,
  MenuSettingsContent,
  MenuSettingsContentItem,
  MenuSettingsContentItemRange,
  MenuSettingsContentItemRangeInput,
  MenuSettingsContentItemButton,
};
