import { useState, useEffect, useRef } from "react";

type IntervalFunc = (timerId: NodeJS.Timer) => any;

function useInterval(callback: IntervalFunc, delay: number) {
  const savedCallback = useRef<Function | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current ? savedCallback.current(id) : (function () {})();
    }, delay);

    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
