import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";

const MechanicsChangeModalBlock = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

interface IMechanicsChangeModalContent {
  isBlur: boolean;
}

const MechanicsChangeModalContent = styled.div<IMechanicsChangeModalContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pxIntoRem(35)};
  border-radius: ${pxIntoRem(80)};
  background-color: rgb(248, 243, 227);
  max-width: ${pxIntoRem(754)};
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  ${({ isBlur }) =>
    isBlur &&
    `
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }`}
`;

const MechanicsChangeModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(120)};
  height: ${pxIntoRem(40)};
  border-radius: ${pxIntoRem(50)};
  background-color: #FFC700;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(17)};
  font-weight: 700;
  line-height: ${pxIntoRem(19)};
  letter-spacing: 0%;
  text-align: left;
  margin: 0 auto;
`;

const MechanicsChangeModalTitle = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(12)};
  font-weight: 400;
  line-height: ${pxIntoRem(13)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(15)};
`;

const MechanicsChangeModalMechanics = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxIntoRem(25)};
  margin-top: ${pxIntoRem(50)};
  z-index: 9999;
`;

const MechanicsChangeModalMechanic = styled.button`
  display: flex;
  align-items: center;
  width: ${pxIntoRem(200)};
  height: ${pxIntoRem(50)};
  padding: ${pxIntoRem(0)} ${pxIntoRem(22)};
  box-shadow: -${pxIntoRem(1)} -${pxIntoRem(1)} ${pxIntoRem(10)} ${pxIntoRem(0)} rgba(0, 0, 0, 0.25),
    ${pxIntoRem(1)} ${pxIntoRem(1)} ${pxIntoRem(10)} ${pxIntoRem(0)} rgba(0, 0, 0, 0.25);
  background: rgb(248, 243, 227);
  cursor: pointer;
`;

const MechanicsChangeModalMechanicImage = styled.img`
  height: ${pxIntoRem(27)};
`;

const MechanicsChangeModalMechanicTitle = styled.span`
  margin-left: ${pxIntoRem(16)};
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(15)};
  font-weight: 500;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
`;

const MechanicsChangeModalMechanicCheck = styled.img`
  width: ${pxIntoRem(11)};
  margin-left: auto;
`;

const MechanicsChangeModalList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${pxIntoRem(35)};
  width: 100%;
  margin-top: ${pxIntoRem(50)};
`;

interface IMechanicsChangeModalListItem {
  isChosen: boolean;
}

const MechanicsChangeModalListItem = styled.button<IMechanicsChangeModalListItem>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(65)};
  height: ${pxIntoRem(65)};
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(85)};
  background: rgb(255, 255, 255);

  ${({ isChosen }) => isChosen && `z-index: 9999;`}
`;

const MechanicsChangeModalListItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(61)};
  height: ${pxIntoRem(61)};
  border: ${pxIntoRem(1)} solid rgb(134, 101, 81);
  border-radius: ${pxIntoRem(77)};
  background: rgb(255, 255, 255);
`;

const MechanicsChangeModalListItemImage = styled.img`
  height: ${pxIntoRem(35)};
  object-fit: contain;
  max-width: 75%;
`;

export {
  MechanicsChangeModalBlock,
  MechanicsChangeModalContent,
  MechanicsChangeModalButton,
  MechanicsChangeModalTitle,
  MechanicsChangeModalMechanics,
  MechanicsChangeModalMechanic,
  MechanicsChangeModalMechanicImage,
  MechanicsChangeModalMechanicTitle,
  MechanicsChangeModalMechanicCheck,
  MechanicsChangeModalList,
  MechanicsChangeModalListItem,
  MechanicsChangeModalListItemContent,
  MechanicsChangeModalListItemImage,
};
