import { Cells } from "../types/cells";
import { Colors } from "../types/colors";
import { Figure, Figures } from "../types/figure";

class Game {
  static getOtherColor(color: Colors) {
    return color === Colors.WHITE ? Colors.BLACK : Colors.WHITE;
  }

  static getCastlingCells(cells: Cells, figure: Figure) {
    if (figure.hasDoneStep || figure.name !== Figures.KING) {
      return {};
    }

    const rooks = (Object.values(cells).filter((figureItem) => !!figureItem) as Figure[])
      .filter((figureItem) => figureItem.name === Figures.ROOK)
      .filter((figureItem) => !figureItem.hasDoneStep);

    const rooksObj = {
      left: rooks.filter((rook) => rook.x === 1 && rook.y === figure.y)[0] ?? null,
      right: rooks.filter((rook) => rook.x === 8 && rook.y === figure.y)[0] ?? null,
    };

    const isCellsBetweenFree = (king: Figure, rook: Figure) => {
      if (king.hasDoneStep || rook.hasDoneStep) {
        return false;
      }

      const isLeftRook = rook.x < king.x;
      if (isLeftRook) {
        for (let i = king.x - 1; i > rook.x; i--) {
          const figure = cells[`${i}-${king.y}`];
          if ((figure && !figure.isFrozen && figure.color !== king.color) || (figure && figure.color === king.color)) {
            return false;
          }
        }
        return true;
      }

      const isRightRook = rook.x > king.x;
      if (isRightRook) {
        for (let i = king.x + 1; i < rook.x; i++) {
          const figure = cells[`${i}-${king.y}`];
          if ((figure && !figure.isFrozen && figure.color !== king.color) || (figure && figure.color === king.color)) {
            return false;
          }
        }

        return true;
      }

      return false;
    };

    const resultCells = [];

    if (
      rooksObj.left &&
      isCellsBetweenFree(figure, rooksObj.left) &&
      !cells[`${figure.x - 2}-${figure.y}`] &&
      !cells[`${figure.x - 1}-${figure.y}`]
    ) {
      resultCells.push({
        x: figure.x - 2,
        y: figure.y,
      });
    }

    if (
      rooksObj.right &&
      isCellsBetweenFree(figure, rooksObj.right) &&
      !cells[`${figure.x + 2}-${figure.y}`] &&
      !cells[`${figure.x + 1}-${figure.y}`]
    ) {
      resultCells.push({
        x: figure.x + 2,
        y: figure.y,
      });
    }

    const result: { [key: string]: boolean } = {};

    resultCells.forEach(({ x, y }) => {
      result[`${x}-${y}`] = true;
    });

    return result;
  }

  static getAvailableCells(
    cells: Cells,
    figure: Figure,
    mode: "eating" | "available",
    isForEnemyDangerousCells: boolean = true
  ) {
    let way: { y: number; x: number }[] = [];

    const toStopWay = (x: number, y: number): boolean => {
      const cell = cells[`${x}-${y}`];
      if (cell === undefined) return true;
      return !!cell;
    };

    const checkCellForMove = (x: number, y: number): boolean => {
      if (toStopWay(x, y)) return false;
      way.push({ x, y });
      return true;
    };

    const verticalTop = (toY: number, fromY: number = figure.y) => {
      for (let i = fromY + 1; i <= toY; i++) {
        if (toStopWay(figure.x, i)) return;
        way.push({ y: i, x: figure.x });
      }
    };

    const verticalBottom = (toY: number, fromY: number = figure.y) => {
      for (let i = fromY - 1; i >= toY; i--) {
        if (toStopWay(figure.x, i)) return;
        way.push({ y: i, x: figure.x });
      }
    };

    const horizontalLeft = (toX: number, fromX: number = figure.x) => {
      for (let i = fromX - 1; i >= toX; i--) {
        if (toStopWay(i, figure.y)) return;
        way.push({ x: i, y: figure.y });
      }
    };

    const horizontalRight = (toX: number, fromX: number = figure.x) => {
      for (let i = fromX + 1; i <= toX; i++) {
        if (toStopWay(i, figure.y)) return;
        way.push({ x: i, y: figure.y });
      }
    };

    const checkDiagonal = () => {
      // top right
      for (let i = 1; i <= 8; i++) {
        if (!checkCellForMove(figure.x + i, figure.y + i)) break;
      }
      // bottom right
      for (let i = 1; i <= 8; i++) {
        if (!checkCellForMove(figure.x + i, figure.y - i)) break;
      }
      // bottom left
      for (let i = 1; i <= 8; i++) {
        if (!checkCellForMove(figure.x - i, figure.y - i)) break;
      }
      for (let i = 1; i <= 8; i++) {
        if (!checkCellForMove(figure.x - i, figure.y + i)) break;
      }
    };

    const checkEatableFiguresByDiagonal = () => {
      for (let i = 1; i <= 8; i++) {
        if (checkEatableOrAlliesCell(figure.x + i, figure.y + i)) break;
      }
      // bottom right
      for (let i = 1; i <= 8; i++) {
        if (checkEatableOrAlliesCell(figure.x + i, figure.y - i)) break;
      }
      // bottom left
      for (let i = 1; i <= 8; i++) {
        if (checkEatableOrAlliesCell(figure.x - i, figure.y - i)) break;
      }
      for (let i = 1; i <= 8; i++) {
        if (checkEatableOrAlliesCell(figure.x - i, figure.y + i)) break;
      }
    };

    const isEatableCell = (x: number, y: number): boolean => {
      const isEatable = cells[`${x}-${y}`];
      return !!(isEatable && figure.color !== isEatable.color);
    };

    const checkEatableCell = (x: number, y: number): boolean => {
      if (isEatableCell(x, y)) {
        way.push({ x, y });
        return true;
      }
      return false;
    };

    const checkEatableOrAlliesCell = (x: number, y: number): boolean => {
      const cell = cells[`${x}-${y}`];
      if (cell && cell.color === figure.color) return true;
      if (isEatableCell(x, y)) {
        way.push({ x, y });
        return true;
      }
      return false;
    };

    // PAWN
    const checkEatableFiguresByPawn = () => {
      if (figure.color === Colors.BLACK) {
        checkEatableCell(figure.x - 1, figure.y - 1);
        checkEatableCell(figure.x + 1, figure.y - 1);
      } else {
        checkEatableCell(figure.x - 1, figure.y + 1);
        checkEatableCell(figure.x + 1, figure.y + 1);
      }
    };

    if (figure.name === Figures.PAWN) {
      const stepSize = figure.hasDoneStep ? 1 : 2;

      if (figure.color === Colors.BLACK) {
        if (mode === "available") {
          verticalBottom(figure.y - stepSize);
        } else {
          way.push({ y: figure.y - 1, x: figure.x - 1 });
          way.push({ y: figure.y - 1, x: figure.x + 1 });
        }
      }

      if (figure.color === Colors.WHITE) {
        if (mode === "available") {
          verticalTop(figure.y + stepSize);
        } else {
          way.push({ y: figure.y + 1, x: figure.x - 1 });
          way.push({ y: figure.y + 1, x: figure.x + 1 });
        }
      }

      checkEatableFiguresByPawn();
    }

    // ROOK
    const checkEatableFiguresByRook = () => {
      // check top
      for (let i = figure.y + 1; i <= 8; i++) {
        if (checkEatableOrAlliesCell(figure.x, i)) break;
      }

      // check bottom
      for (let i = figure.y - 1; i >= 0; i--) {
        if (checkEatableOrAlliesCell(figure.x, i)) break;
      }

      // check left
      for (let i = figure.x - 1; i >= 0; i--) {
        if (checkEatableOrAlliesCell(i, figure.y)) break;
      }

      // check right
      for (let i = figure.x + 1; i <= 8; i++) {
        if (checkEatableOrAlliesCell(i, figure.y)) break;
      }
    };

    if (figure.name === Figures.ROOK) {
      verticalBottom(0);
      verticalTop(8);
      horizontalLeft(0);
      horizontalRight(8);
      checkEatableFiguresByRook();
    }

    // KNIGHT
    const checkMovesByKnight = () => {
      checkCellForMove(figure.x + 1, figure.y + 2);
      checkCellForMove(figure.x - 1, figure.y + 2);
      checkCellForMove(figure.x + 2, figure.y + 1);
      checkCellForMove(figure.x + 2, figure.y - 1);
      checkCellForMove(figure.x + 1, figure.y - 2);
      checkCellForMove(figure.x - 1, figure.y - 2);
      checkCellForMove(figure.x - 2, figure.y - 1);
      checkCellForMove(figure.x - 2, figure.y + 1);
    };

    const checkEatableFiguresByKnight = () => {
      checkEatableOrAlliesCell(figure.x + 1, figure.y + 2);
      checkEatableOrAlliesCell(figure.x - 1, figure.y + 2);
      checkEatableOrAlliesCell(figure.x + 2, figure.y + 1);
      checkEatableOrAlliesCell(figure.x + 2, figure.y - 1);
      checkEatableOrAlliesCell(figure.x + 1, figure.y - 2);
      checkEatableOrAlliesCell(figure.x - 1, figure.y - 2);
      checkEatableOrAlliesCell(figure.x - 2, figure.y - 1);
      checkEatableOrAlliesCell(figure.x - 2, figure.y + 1);
    };

    if (figure.name === Figures.KNIGHT) {
      checkMovesByKnight();
      checkEatableFiguresByKnight();
    }

    // BISHOP
    if (figure.name === Figures.BISHOP) {
      checkDiagonal();
      checkEatableFiguresByDiagonal();
    }

    // QUEEN
    if (figure.name === Figures.QUEEN) {
      checkDiagonal();
      checkEatableFiguresByDiagonal();
      verticalBottom(0);
      verticalTop(8);
      horizontalLeft(0);
      horizontalRight(8);
      checkEatableFiguresByRook();
    }

    // KING
    const checkKingDiagonal = () => {
      checkCellForMove(figure.x + 1, figure.y + 1);
      checkCellForMove(figure.x + 1, figure.y - 1);
      checkCellForMove(figure.x - 1, figure.y - 1);
      checkCellForMove(figure.x - 1, figure.y + 1);
    };

    const checkEatableFiguresByKing = () => {
      checkEatableOrAlliesCell(figure.x + 1, figure.y + 1);
      checkEatableOrAlliesCell(figure.x + 1, figure.y - 1);
      checkEatableOrAlliesCell(figure.x - 1, figure.y - 1);
      checkEatableOrAlliesCell(figure.x - 1, figure.y + 1);
      checkEatableOrAlliesCell(figure.x + 1, figure.y);
      checkEatableOrAlliesCell(figure.x - 1, figure.y);
      checkEatableOrAlliesCell(figure.x, figure.y + 1);
      checkEatableOrAlliesCell(figure.x, figure.y - 1);
    };

    const whiteFigures = Game.getFiguresBySide(cells, Colors.WHITE);
    const blackFigures = Game.getFiguresBySide(cells, Colors.BLACK);

    const kings = {
      [Colors.WHITE]: whiteFigures.filter((figure) => figure.name === Figures.KING) as Figure[],
      [Colors.BLACK]: blackFigures.filter((figure) => figure.name === Figures.KING) as Figure[],
    };

    const king = kings[figure.color][0];

    if (figure.name === Figures.KING) {
      verticalBottom(figure.y - 1);
      verticalTop(figure.y + 1);
      horizontalLeft(figure.x - 1);
      horizontalRight(figure.x + 1);
      checkKingDiagonal();
      checkEatableFiguresByKing();

      // Если поиск опастных для хода ячеек противника

      // Фильтр на те клетки, которые может убить король или сходить на них
      if (isForEnemyDangerousCells && kings[figure.color].length === 1) {
        way = way.filter(({ x, y }) => {
          return Game.canKingKillOrStep(king, cells, x, y);
        });
      }
    } else {
      // Получаем фигуру которая может сьесть клетку
      if (isForEnemyDangerousCells && kings[figure.color].length === 1) {
        // Если есть фигуры которые можно перекрыть
        way = way.filter(({ x, y }) => Game.willFilledCellSaveKing(figure, king, cells, x, y));

        // Если есть фигуры которые можно убить
        const killingKingFigures = Game.getFiguresKillingCell(cells, king.x, king.y);

        if (killingKingFigures.length) {
          if (killingKingFigures.length === 1) {
            const killingKingFigure = killingKingFigures[0];
            const defendingKillingKingFigures = Game.getFiguresKillingCell(
              cells,
              killingKingFigure.x,
              killingKingFigure.y
            );
            const defendingKillingKingFiguresFiltered = defendingKillingKingFigures.filter((figureData) => {
              return figureData.x === figure.x && figureData.y === figure.y;
            });

            if (
              defendingKillingKingFiguresFiltered.length &&
              defendingKillingKingFiguresFiltered[0].x === figure.x &&
              defendingKillingKingFiguresFiltered[0].y === figure.y
            ) {
              way = [
                {
                  y: killingKingFigure.y,
                  x: killingKingFigure.x,
                },
              ];
            }
          } else {
            way = [];
          }
        }

        // Может ли фигура отойти и король останется в безопастности
        way = way.filter(({ x, y }) => {
          const replacedFigure = cells[`${x}-${y}`];

          cells[`${x}-${y}`] = figure;
          cells[`${figure.x}-${figure.y}`] = null;

          const dangerousCells = Game.getAvailableCellsBySide(cells, "eating", Game.getOtherColor(figure.color), false);

          cells[`${x}-${y}`] = replacedFigure;
          cells[`${figure.x}-${figure.y}`] = figure;

          return !dangerousCells[`${king.x}-${king.y}`];
        });
      }
    }

    way = way.filter(({ x, y }) => {
      const figure = cells[`${x}-${y}`];
      if (!figure) {
        return true;
      }
      return !figure.isDefended;
    });

    const speededUpFigure = Game.getFiguresBySide(cells, figure.color).find((figure) => !!figure.isSpeededUp) ?? null;

    if (figure.isFrozen || (speededUpFigure && speededUpFigure?.id !== figure.id)) {
      way = [];
    }
    /*
    1) При шахе короля сделать возможность ходить только королем, или любой фигурой для его защиты - сделано
    2) Запретить королю рубить, если будет опасность -
    3) ИИ
    */

    let obj: { [key: string]: boolean } = {};

    // Нужно отодвигать пешку и получать опасные клетки пешек врагов
    way.forEach((el) => {
      obj[`${el.x}-${el.y}`] = true;
    });

    return obj;
  }

  static getAvailableCellsBySide(
    cells: Cells,
    mode: "eating" | "available",
    side: Colors,
    isForEnemyDangerousCells: boolean
  ): { [key: string]: boolean } {
    let availableCells: { [key: string]: boolean } = {};

    const figures = Game.getFiguresBySide(cells, side);
    for (let figure of figures) {
      availableCells = {
        ...availableCells,
        ...Game.getAvailableCells(cells, figure, mode, isForEnemyDangerousCells),
      };
    }

    return availableCells;
  }

  // Получение фигур, убивающих x-y
  private static getFiguresKillingCell(cells: Cells, x: number, y: number) {
    const killingFigures = [];

    const figure = cells[`${x}-${y}`];
    if (!figure) {
      return [];
    }

    const enemyColor = Game.getOtherColor(figure.color);
    const enemyFigures = Game.getFiguresBySide(cells, enemyColor);

    for (let figure of enemyFigures) {
      const availableCells = Game.getAvailableCells(cells, figure, "eating", false);
      if (availableCells[`${x}-${y}`]) {
        killingFigures.push(figure);
      }
    }

    return killingFigures;
  }
  // Может ли король убить фигуру на клетке x-y
  private static canKingKillOrStep(king: Figure, cells: Cells, x: number, y: number) {
    const prevFigure = cells[`${x}-${y}`];

    cells[`${x}-${y}`] = king;
    cells[`${king.x}-${king.y}`] = null;

    const enemyDangerousCells = Game.getAvailableCellsBySide(cells, "eating", Game.getOtherColor(king.color), false);

    cells[`${x}-${y}`] = prevFigure;
    cells[`${king.x}-${king.y}`] = king;

    return !enemyDangerousCells[`${x}-${y}`];
  }

  private static willFilledCellSaveKing(figure: Figure, king: Figure, cells: Cells, x: number, y: number) {
    const prevValue = cells[`${x}-${y}`];

    cells[`${x}-${y}`] = figure;

    const killingFigures = Game.getFiguresKillingCell(cells, king.x, king.y);

    cells[`${x}-${y}`] = prevValue;

    return !!!killingFigures.length;
  }

  private static getFiguresBySide(cells: Cells, side: Colors) {
    const figures = Object.values(cells)
      .filter((cell) => !!cell)
      .filter((cell) => cell?.color === side) as Figure[];
    return figures;
  }
}

export default Game;
