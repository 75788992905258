import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const NavigationBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 0 0 auto;
`;

const NavigationButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${pxIntoRem(10)}; /* Добавьте отступы, если необходимо */
    flex-shrink: 0;
    cursor: pointer;
    overflow: hidden;
    background-color: transparent;
    width: 33%;
`;



const NavigationButtonText = styled.span`
  font-size: 2rem; /* Пример размера шрифта */
  display: block; /* Текст будет отображаться под изображением */
  text-align: center; /* Центрирование текста */
    font-family: "Coda", system-ui;
    color: rgb(0, 0, 0);
    font-weight: 700;
    // line-height: ${pxIntoRem(50)};
    padding-bottom: ${pxIntoRem(13)};
    letter-spacing: 0%;
    margin: 0 auto;
`;


const NavigationButtonImage = styled.img`
    width: 4.750rem; /* Фиксированная ширина */
    height: 4.750rem; /* Фиксированная высота */
    border-radius: 50%;
    object-fit: contain; /* Сохранение пропорций изображения */
`;

export { NavigationBlock, NavigationButton, NavigationButtonImage, NavigationButtonText };
