import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const ProfileBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const ProfileTitle = styled.h1`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  margin: 0 auto;
  margin-top: ${pxIntoRem(44)};
  margin-bottom: ${pxIntoRem(156)};
`;

const ProfileDescription = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(28)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  margin: 0 auto;
  margin-top: ${pxIntoRem(-140)};
  margin-bottom: ${pxIntoRem(156)};
`;

export { ProfileBlock, ProfileTitle, ProfileDescription };
