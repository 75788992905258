import React, { useContext, useEffect } from "react";
import { websocketContext } from "../contexts/websocket";
import { ServerEvent } from "../types/events";
import usersService from "../services/usersService";

function useWebsocketEvent(websocketEvent: string, callback: Function) {
  const { ws } = useContext(websocketContext);

  useEffect(() => {
    function onMessage(e: MessageEvent) {
      const { event, data } = JSON.parse(e.data);
      if (event && websocketEvent === event) {
        callback(data);
      }
    }

    ws.addEventListener("message", onMessage);

    return () => {
      ws.removeEventListener("message", onMessage);
    };
  }, []);
}

export function useWebsocketEventDispatch() {
  const { ws } = useContext(websocketContext);

  function dispatchEvent(event: ServerEvent, data: any) {
    let dataJson = JSON.stringify({
      event,
      data,
    });

    const user = usersService.getUser();
    if (user) {
      dataJson = JSON.stringify({
        event,
        data,
        token: user.token,
      });
    }

    setTimeout(() => {
      if (ws.readyState === 1) {
        ws.send(dataJson);
      } else {
        dispatchEvent(event, data);
      }
    }, 5);
  }

  return dispatchEvent;
}

export default useWebsocketEvent;
