import {ProfileBlock, ProfileDescription, ProfileTitle} from "./ProfileStyles";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import { useEffect, useState } from "react";
import User from "../../types/user";
import usersService from "../../services/usersService";
import ProfileButtons from "./components/ProfileButtons/ProfileButtons";
import authService from "../../services/authService";

const buttons = [
  {
    image: {
      src: "/images/personal.png",
      width: 75,
      height: 75,
    },
    text: "Personal data",
    link: "/profile/personal",
  },
  {
    image: {
      src: "/images/parties.png",
      width: 75,
      height: 75,
    },
    text: "View games",
    link: "/profile/parties",
  },
];

function Profile() {
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [bonus, setBonus] = useState(0);

  const getData = async () => {
    const res = await authService.getUser();
    if (res.status === "error") {
      navigate("/auth/registration");
      return;
    }
    setBonus(res.data.bonus);
    setLogin(res.data.login);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ProfileBlock>


      <ProfileTitle>{login}</ProfileTitle>
      <ProfileDescription>Bonuses: {bonus}</ProfileDescription>

      <ProfileButtons buttons={buttons} />
      <Navigation />
    </ProfileBlock>
  );
}

export default Profile;
