import React, { FC } from "react";
import { Magic } from "../../../types/magic";
import { Colors } from "../../../types/colors";
import {
  BoardControllerBlock,
  BoardControllerButton,
  BoardControllerButtonContent,
  BoardControllerButtonContentCooldown,
  BoardControllerButtonContentCooldownContent,
  BoardControllerButtonImage,
} from "./BoardControllerStyles";
import Player from "../../../types/player";

interface IBoardController {
  side: Colors;
  currentSide: Colors | null;
  player: Player | null;
  magic?: Magic | null;
  cooldown: {
    [Magic.Defend]: number;
    [Magic.Freeze]: number;
    [Magic.SpeedUp]: number;
  };
  onClick?: (eventType: Magic) => void;
}

const magics = [
  {
    image: "/images/wind.png",
    magicType: Magic.SpeedUp,
    size: "60%",
  },
  {
    image: "/images/snowflake.png",
    magicType: Magic.Freeze,
    size: "52%",
  },
  {
    image: "/images/shield.png",
    magicType: Magic.Defend,
    size: "50%",
  },
];

const BoardController: FC<IBoardController> = ({ magic, side, cooldown, player, currentSide, onClick }) => {
  const onMagicClick = (magic: Magic) => {
    if (cooldown[Magic.Defend] === 3 || cooldown[Magic.Freeze] === 3 || cooldown[Magic.SpeedUp] === 3) {
      return;
    }

    onClick && onClick(magic);
  };

  return (
    <BoardControllerBlock
      isBlack={side === Colors.BLACK}
      isWhite={side === Colors.WHITE}
      side={player?.side ?? Colors.WHITE}
    >
      {magics.map(({ image, size, magicType }) => {
        const cooldownCounter = cooldown[magicType];

        return (
          <BoardControllerButton
            isActive={magic === magicType && player?.side === side && player?.side === currentSide}
            key={magicType}
            onClick={() => player?.side === side && onMagicClick(magicType)}
            side={player?.side ?? Colors.WHITE}
          >
            <BoardControllerButtonContent>
              {!!cooldownCounter && (
                <BoardControllerButtonContentCooldown cooldown={cooldownCounter}>
                  <BoardControllerButtonContentCooldownContent>
                    {cooldownCounter}
                  </BoardControllerButtonContentCooldownContent>
                </BoardControllerButtonContentCooldown>
              )}

              <BoardControllerButtonImage alt="image" src={image} width={size} />
            </BoardControllerButtonContent>
          </BoardControllerButton>
        );
      })}
    </BoardControllerBlock>
  );
};

export default BoardController;
