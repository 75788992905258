import appConfig from "../configs/appConfig";
import { BoardHistory, BoardHistoryItem } from "../types/boardHistory";
import User from "../types/user";
import { fetchData } from "../utils/fetch";

interface GetHistory {
  req: {
    boardId: string;
  };
  res: {
    status: "success" | "error";
    data: BoardHistory[];
  };
}

interface GetHistories {
  req: {};
  res: {
    status: "success" | "error";
    data: BoardHistoryItem[];
  };
}

interface ChangeFavourite {
  req: {
    boardId: string;
  };
  res: {
    status: "success" | "error";
    data: {
      message: string;
    };
  };
}

class HistoryRepository {
  async getHistory({ boardId }: GetHistory["req"]): Promise<GetHistory["res"]> {
    const res = await fetchData(appConfig.apiUrl + `/histories/${boardId}`, {
      method: "GET",
    });

    const json = await res.json();

    return json;
  }

  async getHistories(): Promise<GetHistories["res"]> {
    const res = await fetchData(appConfig.apiUrl + `/histories`, {
      method: "GET",
    });

    const json = await res.json();

    return json;
  }

  async changeFavourite({ boardId }: ChangeFavourite["req"]): Promise<ChangeFavourite["res"]> {
    const res = await fetchData(appConfig.apiUrl + `/histories/${boardId}/favourite`, {
      method: "POST",
    });

    const json = await res.json();

    return json;
  }
}

export default new HistoryRepository();
