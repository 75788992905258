import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const MenuBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const MenuButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${pxIntoRem(50)}; /* Уменьшаем отступ между кнопками */
    flex: 0 0 auto;
    margin: 20px auto;
`;

const MenuButtonCreate = styled.button`
    // margin-top: ${pxIntoRem(80)};
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(${pxIntoRem(425)} * 1.3); 
    height: calc(${pxIntoRem(106)} * 1.4); 
    position: relative;
    background: #FFC700;
    cursor: pointer;
    color: #000;
    font-family: "Coda", system-uia;
    font-size: 1.850rem;
    font-style: normal;
    font-weight: 800;
    line-height: ${pxIntoRem(40)};
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 50px;
    white-space: nowrap;
    letter-spacing: ${pxIntoRem(6)};
`;

const MenuButtonFriend = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(${pxIntoRem(425)} * 1.3);
    height: calc(${pxIntoRem(106)} * 1.4);    
    position: relative;
    background: #64800F;
    margin-top: 2px; /* Убираем верхний отступ, так как используем gap */
    cursor: pointer;
    color: #000;
    font-family: "Coda", system-uia;
    font-size: 1.850rem;
    font-style: normal;
    font-weight: 800;
    line-height: ${pxIntoRem(40)};
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 50px;
    white-space: nowrap;
    letter-spacing: ${pxIntoRem(4)};
`;


const MenuBoard = styled.div`
  position: relative;
  width: fit-content;
  margin: ${pxIntoRem(100)} auto ${pxIntoRem(100)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuBoardImage = styled.img`
  width: ${pxIntoRem(605)};
`;

const MenuBoardFrame = styled.img`
  position: absolute;
  width: 149%;
  height: 117%;

  @media (max-width: ${pxIntoRem(400)}) {
    width: 144%;
    height: 112%;
  }
`;

const MenuLobbiesBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const MenuLobbiesContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${pxIntoRem(800)};
  padding: ${pxIntoRem(35)} ${pxIntoRem(135)} ${pxIntoRem(136)} ${pxIntoRem(135)};
  border-radius: ${pxIntoRem(140)};
  background: #F8F3E3;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const MenuLobbiesLogo = styled.img`
  width: ${pxIntoRem(78.9)};
  height: ${pxIntoRem(80)};
  flex-shrink: 0;
`;

const MenuLobbiesTitle = styled.h2`
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: ${pxIntoRem(20)};
`;

const MenuLobbiesColumn = styled.div`
  display: flex;
  width: ${pxIntoRem(162)};
  flex-shrink: 0;

  &:first-of-type {
    width: ${pxIntoRem(150)};
  }

  &:last-of-type {
    width: ${pxIntoRem(120)};
  }
`;

const MenuLobbiesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${pxIntoRem(35)};
  padding: 0px ${pxIntoRem(38)};
  gap: ${pxIntoRem(18)};
`;

interface IMenuLobbiesHeaderImage {
    $width: number;
}

const MenuLobbiesHeaderImage = styled.img<IMenuLobbiesHeaderImage>`
  margin-right: ${pxIntoRem(10)};
  width: ${({ $width }) => pxIntoRem($width)};
`;

const MenuLobbiesHeaderTitle = styled.h3`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  position: relative;
  top: ${pxIntoRem(8)};
`;

const MenuLobbiesItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(10)};
  margin-top: ${pxIntoRem(25)};
  width: 100%;
  flex-shrink: 0;
`;

const MenuLobbiesItem = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(30)};
  height: ${pxIntoRem(50)};
  width: 100%;
  padding: ${pxIntoRem(5)};
  width: 100%;
`;

const MenuLobbiesItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(30)};
  padding: 0px ${pxIntoRem(22)};

  ${MenuLobbiesColumn} {
  }
`;

const MenuLobbiesItemContentText = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(12)};
  font-weight: 400;
  line-height: ${pxIntoRem(13)};
  letter-spacing: 0%;
  text-align: left;

  &:last-child {
    margin-left: ${pxIntoRem(16)};
  }
`;

const MenuLobbiesItemContentSkills = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${pxIntoRem(54)};
  gap: ${pxIntoRem(15)};
`;

const MenuLobbiesItemContentSkill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(15)};
  height: ${pxIntoRem(15)};
  border: ${pxIntoRem(0.5)} solid #858585;
  border-radius: ${pxIntoRem(85)};
  background: rgb(255, 255, 255);
`;

const MenuLobbiesItemContentSkillContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(13)};
  height: ${pxIntoRem(13)};
  border: ${pxIntoRem(0.5)} solid rgb(134, 101, 81);
  border-radius: ${pxIntoRem(77)};
  background: rgb(255, 255, 255);
`;

const MenuLobbiesItemContentSkillImage = styled.img`
  width: ${pxIntoRem(7.67)};
`;

const MenuLobbiesItemContentTime = styled(MenuLobbiesItemContentText)`
  margin-left: ${pxIntoRem(72)}!important;
`;

const MenuLobbiesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(30)};
  width: ${pxIntoRem(250)};
  height: ${pxIntoRem(50)};
  margin-top: ${pxIntoRem(20)};
  cursor: pointer;
  border: 1px solid #B18A00;
  background: #FFC700;
`;

export { MenuBlock, MenuButtonCreate, MenuButtonFriend, MenuButtons, MenuBoard, MenuBoardImage, MenuBoardFrame,
    MenuLobbiesBlock,
    MenuLobbiesLogo,
    MenuLobbiesTitle,
    MenuLobbiesContent,
    MenuLobbiesColumn,
    MenuLobbiesHeader,
    MenuLobbiesHeaderImage,
    MenuLobbiesHeaderTitle,
    MenuLobbiesItem,
    MenuLobbiesItemContent,
    MenuLobbiesItems,
    MenuLobbiesItemContentText,
    MenuLobbiesItemContentSkill,
    MenuLobbiesItemContentSkillContent,
    MenuLobbiesItemContentSkills,
    MenuLobbiesItemContentTime,
    MenuLobbiesItemContentSkillImage,
    MenuLobbiesButton,
};
