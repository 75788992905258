import React, { FC, useState } from "react";
import {
  MechanicsChangeModalBlock,
  MechanicsChangeModalButton,
  MechanicsChangeModalContent,
  MechanicsChangeModalList,
  MechanicsChangeModalListItem,
  MechanicsChangeModalListItemContent,
  MechanicsChangeModalListItemImage,
  MechanicsChangeModalMechanic,
  MechanicsChangeModalMechanicCheck,
  MechanicsChangeModalMechanicImage,
  MechanicsChangeModalMechanicTitle,
  MechanicsChangeModalMechanics,
  MechanicsChangeModalTitle,
} from "./MechanicsChangeModalStyles";
import { Magic, getMagic } from "../../../../types/magic";

interface IMechanicsChangeModal {
  magic: Magic[];
  availableMagic: Magic[];
  onClose: () => void;
  onChange: (magic: Magic[]) => void;
}

const MechanicsChangeModal: FC<IMechanicsChangeModal> = ({ magic, availableMagic, onClose, onChange }) => {
  const [chosenMagicIdx, setChosenMagicIdx] = useState<number | null>(null);

  const chooseMagic = (idxToChoose: number) => {
    if (chosenMagicIdx === idxToChoose) {
      setChosenMagicIdx(null);
      return;
    }

    setChosenMagicIdx(idxToChoose);
  };

  const changeMagic = (idxToChange: number) => {
    if (chosenMagicIdx === null) return;

    const newMagic = [...magic];

    newMagic[idxToChange] = availableMagic[chosenMagicIdx];

    onChange(newMagic);
    setChosenMagicIdx(null);
  };

  return (
    <MechanicsChangeModalBlock onClick={onClose}>
      <MechanicsChangeModalContent onClick={(e) => e.stopPropagation()} isBlur={chosenMagicIdx !== null}>
        <MechanicsChangeModalButton>In game</MechanicsChangeModalButton>
        <MechanicsChangeModalTitle>To select other mechanics, click on them</MechanicsChangeModalTitle>

        <MechanicsChangeModalMechanics>
          {magic.map((magicItem, idx) => {
            const magicObj = getMagic(magicItem);

            return (
              <MechanicsChangeModalMechanic key={magicObj.title + idx} onClick={() => changeMagic(idx)}>
                <MechanicsChangeModalMechanicImage src={magicObj.image} />

                <MechanicsChangeModalMechanicTitle>{magicObj.title}</MechanicsChangeModalMechanicTitle>

                <MechanicsChangeModalMechanicCheck src="/images/check.png" />
              </MechanicsChangeModalMechanic>
            );
          })}
        </MechanicsChangeModalMechanics>

        <MechanicsChangeModalList>
          {availableMagic.map((availableMagicItem, idx) => {
            const magicObj = getMagic(availableMagicItem);

            return (
              <MechanicsChangeModalListItem
                onClick={(e) => chooseMagic(idx)}
                isChosen={chosenMagicIdx === idx}
                key={availableMagicItem + idx}
              >
                <MechanicsChangeModalListItemContent>
                  <MechanicsChangeModalListItemImage src={magicObj.image} />
                </MechanicsChangeModalListItemContent>
              </MechanicsChangeModalListItem>
            );
          })}
        </MechanicsChangeModalList>
      </MechanicsChangeModalContent>
    </MechanicsChangeModalBlock>
  );
};

export default MechanicsChangeModal;
