import moment from "moment";
import User from "../../../server/types/user";
import authService from "../services/authService";
import usersService from "../services/usersService";

async function fetchData(input: RequestInfo | URL, init: RequestInit, disableDefaultHeaders = false) {
  const user = usersService.getUser();

  const headers = disableDefaultHeaders
    ? { ...init.headers }
    : {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        ...init.headers,
      };

  return fetch(input, {
    ...init,
    headers,
  });
}

export { fetchData };
