import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Index from "./pages/Index/Index";
import Login from "./pages/Auth/Login/Login";
import Registration from "./pages/Auth/Registration/Registration";
import Menu from "./pages/Menu/Menu";
import Board from "./pages/Board/Board";
import Profile from "./pages/Profile/Profile";
import Personal from "./pages/Profile/Personal/Personal";
import Mechanics from "./pages/Profile/Mechanics/Mechanics";
import Parties from "./pages/Profile/Parties/Parties";
import History from "./pages/Board/History/History";
import TgLogin from "./pages/Auth/TelegramLogin/TgLogin";
import Referal from "./pages/Auth/ReferalWidget/Referal";
import ReferalAuth from "./pages/Auth/ReferalAuth/ReferalAuth";


const AppBlock = styled.div`
  display: flex;
  width: 100%;
  font-family: "Coda", system-ui;
`;

const AppBackground = styled.img`
  position: fixed;
  left: 0;
  top: 0;
  object-fit: cover;
  height: 100vh;
  width: 100%;
  z-index: 999;
`;

const AppRoutes = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9999;
  background: #EFE9D4;
`;

function App() {
  return (
    <AppBlock>
      <AppRoutes>
        <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/tg_login" element={<TgLogin />} />
          <Route path="/auth/referal" element={<ReferalAuth />} />
          <Route path="/auth/registration" element={<Registration />} />
          <Route path="/menu" element={<Menu />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/mechanics" element={<Mechanics />} />
          <Route path="/profile/parties" element={<Parties />} />
          <Route path="/profile/personal" element={<Personal />} />

          <Route path="/board/:boardId" element={<Board />} />
          <Route path="/board/:boardId/history" element={<History />} />

          <Route path="/referal" element={<Referal />} />
        </Routes>
      </AppRoutes>
    </AppBlock>
  );
}

export default App;
