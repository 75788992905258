import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const PartiesBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  // padding-bottom: ${pxIntoRem(28)};
`;

const PartiesTitle = styled.h1`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  margin: 0 auto;
  margin-top: ${pxIntoRem(44)};
`;

const PartiesSelects = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${pxIntoRem(50)} ${pxIntoRem(10)} ${pxIntoRem(30)} ${pxIntoRem(10)};
  gap: ${pxIntoRem(40)};
  width: 100%;
`;

const PartiesSelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const PartiesSelectTitle = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(25)};
  font-weight: 400;
  line-height: ${pxIntoRem(28)};
  letter-spacing: 0%;
  text-align: left;
  padding-left: ${pxIntoRem(32)};
`;

const PartiesList = styled.div`
  margin: 0 auto;
  display: grid;
  // grid-template-columns: ${pxIntoRem(375)} ${pxIntoRem(375)};
  grid-template-columns: 1fr 1fr;
  // grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  // grid-template-rows: ${pxIntoRem(200)};
  // grid-template-rows: 1fr 1fr;
  grid-template-rows: auto;
  gap: ${pxIntoRem(30)} ${pxIntoRem(67)};
  // margin-bottom: ${pxIntoRem(350)};
  flex: 1 1 auto;
  // height: 75%;
  width: 100%;
  // height: 100%;
  align-items: center;
  height: auto;
    align-content: start; /* Начало контента */
    justify-items: stretch; /* Растянуть элементы по ширине */
    align-items: center; /* Центрирование элементов по высоте */
`;

const PartiesButtons = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${pxIntoRem(70)} ${pxIntoRem(0)} ${pxIntoRem(30)};
  border-top: ${pxIntoRem(3)} solid #858585;
  background: rgb(248, 243, 227);
`;

export {
  PartiesBlock,
  PartiesTitle,
  PartiesSelects,
  PartiesSelectBlock,
  PartiesSelectTitle,
  PartiesList,
  PartiesButtons,
};
