import React, { FC, useMemo } from "react";
import styles from "./Figure.module.scss";
import { Colors } from "../../../types/colors";
import { Figure, Figures } from "../../../types/figure";
import { BoardFigureBlock } from "./BoardFigureStyles";
import useGIFAnimation from "../../../hooks/useGIFAnimation";
import BoardFigureTransform from "./BoardFigureTransform/BoardFigureTransform";

interface BoardFigureProps {
  figure: Figure;
  onClick?: (figure: Figure) => void;
  onTransform?: (figure: Figures) => void;
  isInCheck?: boolean;
  side: Colors;
}

const BoardFigure: FC<BoardFigureProps> = ({ figure, onTransform, onClick, isInCheck, side }) => {
  // const animation = useGIFAnimation(
  //   {
  //     imageSrc: "/images/cats.gif",
  //   },
  //   !!figure.isFrozen
  // );

  // const animation = useGIFAnimation(
  //   {
  //     imageSrc: "",
  //   },
  //   !!figure.isFrozen
  // );

  // const animation = useGIFAnimation(
  //   {
  //     imageSrc: "",
  //   },
  //   !!figure.isFrozen
  // );

  const isBlack = figure.color === Colors.BLACK;

  const getBishop = (): JSX.Element => {
    if (isBlack) {
      return <img src="/images/figures/bishop.png" alt="bishop" />;
    }

    return <img src="/images/figures/wbishop.png" alt="bishop" />;
  };

  const getKing = (): JSX.Element => {
    if (isBlack) {
      return <img src="/images/figures/king.png" alt="king" />;
    }

    return <img src="/images/figures/wking.png" alt="king" />;
  };

  const getKnight = (): JSX.Element => {
    if (isBlack) {
      return <img src="/images/figures/knight.png" alt="knight" />;
    }

    return <img src="/images/figures/wknight.png" alt="knight" />;
  };

  const getPawn = (): JSX.Element => {
    if (isBlack) {
      return <img src="/images/figures/pawn.png" alt="pawn" />;
    }

    return <img src="/images/figures/wpawn.png" alt="pawn" />;
  };

  const getQueen = (): JSX.Element => {
    if (isBlack) {
      return <img src="/images/figures/queen.png" alt="queen" />;
    }

    return <img src="/images/figures/wqueen.png" alt="queen" />;
  };

  const getRook = (): JSX.Element => {
    if (isBlack) {
      return <img src="/images/figures/rook.png" alt="rook" />;
    }

    return <img src="/images/figures/wrook.png" alt="rook" />;
  };

  const getFigure = (): JSX.Element => {
    switch (figure.name) {
      case Figures.BISHOP:
        return getBishop();
      case Figures.KING:
        return getKing();
      case Figures.KNIGHT:
        return getKnight();
      case Figures.PAWN:
        return getPawn();
      case Figures.QUEEN:
        return getQueen();
      case Figures.ROOK:
        return getRook();
    }
  };

  const figurePosition = {
    left: (figure.x - 1) * 12.5 + "%",
    bottom: (figure.y - 1) * 12.5 + "%",
  };

  const circlePosition = {
    left: (figure.x - 1) * 12.5 - 3.7 + "%",
    bottom: (figure.y - 1) * 12.5 - 3.7 + "%",
  };

  // {transformFigureModal && <BoardFiguresModal figures={[Figures.KNIGHT, Figures.ROOK, Figures.QUEEN, Figures.KING, Figures.BISHOP]} onClick={transformFigure} color={player?.side ?? Colors.WHITE} />}

  const correctY = useMemo(() => {
    return figure.color === Colors.WHITE ? 8 : 1;
  }, [figure]);

  return (
    <>
      {figure.y === correctY && side === figure.color && figure.name === Figures.PAWN && (
        <BoardFigureTransform
          onClick={(figure) => onTransform && onTransform(figure)}
          figures={[Figures.KNIGHT, Figures.ROOK, Figures.QUEEN, Figures.KING, Figures.BISHOP]}
          color={figure.color}
          left={circlePosition.left}
          bottom={circlePosition.bottom}
        />
      )}
      <BoardFigureBlock
        onClick={() => onClick && onClick(figure)}
        isInCheck={isInCheck}
        style={{ left: figurePosition.left, bottom: figurePosition.bottom }}
        side={side}
      >
        {getFigure()}
      </BoardFigureBlock>
    </>
  );
};

export default BoardFigure;
