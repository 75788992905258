import React, { FC } from "react";
import { ProfileButton, ProfileButtonImage, ProfileButtonText, ProfileButtonsBlock } from "./ProfileButtonsStyles";
import { useNavigate } from "react-router-dom";

interface ProfileButtonComponent {
  buttons: {
    image: {
      src: string;
      width: number;
      height: number;
    };
    text: string;
    link: string;
  }[];
}

const ProfileButtons: FC<ProfileButtonComponent> = ({ buttons }) => {
  const navigate = useNavigate();

  return (
    <ProfileButtonsBlock>
      {buttons.map((button) => {
        return (
          <ProfileButton key={button.text} onClick={() => navigate(button.link)}>
            <ProfileButtonImage src={button.image.src} width={button.image.width} height={button.image.height} />
            <ProfileButtonText>{button.text}</ProfileButtonText>
          </ProfileButton>
        );
      })}
    </ProfileButtonsBlock>
  );
};

export default ProfileButtons;
