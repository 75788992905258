import React, { useState } from "react";
import {
  RegistrationBlock,
  RegistrationButton,
  RegistrationButtons,
  RegistrationContent,
  RegistrationInput,
  RegistrationInputField,
  RegistrationInputFieldWrapper,
  RegistrationInputTitle,
  RegistrationInputs,
  RegistrationLogo,
  RegistrationTitle,
} from "./RegistrationStyles";
import authService from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import usersService from "../../../services/usersService";

function generateRefCode(length = 10) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

function Registration() {
  const navigate = useNavigate()
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [photo_id, setPhotoId] = useState("");
  const [telegram_id, setTelegramId] = useState(0);
  const [repeatedPassword, setRepeatedPassword] = useState("");

  async function onRegistration() {
    const ref_code =  generateRefCode();
    const res = await authService.registration({
      login,
      email,
      password,
      photo_id,
      bonus: 100,
      ref_code,
      telegram_id,
    });

    if (res.status === "success") {
      usersService.saveUser(res.data);

      navigate("/menu");
    }
  }

  return (
    <RegistrationBlock>
      <RegistrationContent>
        <RegistrationLogo alt="logo" src="/images/logo.png" />
        <RegistrationTitle>Создание аккаунта</RegistrationTitle>

        <RegistrationInputs>
          <RegistrationInput>
            <RegistrationInputTitle>Имя</RegistrationInputTitle>
            <RegistrationInputFieldWrapper>
              <RegistrationInputField value={login} onChange={(e) => setLogin(e.currentTarget.value)} />
            </RegistrationInputFieldWrapper>
          </RegistrationInput>

          <RegistrationInput>
            <RegistrationInputTitle>E-mail</RegistrationInputTitle>
            <RegistrationInputFieldWrapper>
              <RegistrationInputField value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
            </RegistrationInputFieldWrapper>
          </RegistrationInput>

          <RegistrationInput>
            <RegistrationInputTitle>Пароль</RegistrationInputTitle>
            <RegistrationInputFieldWrapper>
              <RegistrationInputField value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
            </RegistrationInputFieldWrapper>
          </RegistrationInput>

          <RegistrationInput>
            <RegistrationInputTitle>Подтвердить Пароль</RegistrationInputTitle>
            <RegistrationInputFieldWrapper>
              <RegistrationInputField
                value={repeatedPassword}
                onChange={(e) => setRepeatedPassword(e.currentTarget.value)}
              />
            </RegistrationInputFieldWrapper>
          </RegistrationInput>
        </RegistrationInputs>

        {!!password && !!repeatedPassword && password === repeatedPassword && (
          <RegistrationButtons>
            <RegistrationButton onClick={onRegistration}>Далее</RegistrationButton>
          </RegistrationButtons>
        )}
      </RegistrationContent>
    </RegistrationBlock>
  );
}

export default Registration;
