enum Magic {
  Freeze = "freeze",
  Defend = "defend",
  SpeedUp = "speedUp",
}

const getMagic = (magic: Magic | null) => {
  switch (magic) {
    case Magic.SpeedUp:
      return {
        title: "Boost",
        description: "Accelerates a figure by 1 move",
        image: "/images/wind.png",
      };
    case Magic.Defend:
      return {
        title: "Protection",
        description: "Protects a piece for 1 move",
        image: "/images/shield.png",
      };
    case Magic.Freeze:
      return {
        title: "Frozen",
        description: "Freezes the enemy piece for 1 turn",
        image: "/images/snowflake.png",
      };
    default:
      return {
        title: "",
        description: "",
        image: "",
      };
  }
};

export { Magic, getMagic };
