import React, { FC, SyntheticEvent } from "react";
import {
  PartyBlock,
  PartyContent,
  PartyContentHeart,
  PartyContentItem,
  PartyContentItemText,
  PartyContentItemTitle,
  PartyImage,
} from "./PartyStyles";
import { BoardHistoryItem } from "../../../../types/boardHistory";
import { useNavigate } from "react-router-dom";
import historyService from "../../../../services/historyService";

interface IParty {
  party: BoardHistoryItem;
  onChange(): void;
}

const Party: FC<IParty> = ({ party, onChange }) => {
  const navigate = useNavigate();

  async function changeFavourite(e: SyntheticEvent) {
    e.stopPropagation();

    const res = await historyService.changeFavourite({
      boardId: party.id,
    });

    if (res.status === "success") {
      onChange();
    }
  }

  return (
    <PartyBlock onClick={() => navigate(`/board/${party.id}/history`)}>
      <PartyImage src="/images/board-mini.png" />
      <PartyContent>
        <PartyContentHeart
          src={!party.isFavourite ? "/images/heart.svg" : "/images/heart_active.svg"}
          onClick={changeFavourite}
        />

        <PartyContentItem>
          <PartyContentItemTitle>Result:</PartyContentItemTitle>
          <PartyContentItemText>{party.isWin ? "Win" : "Lose"}</PartyContentItemText>
        </PartyContentItem>

        <PartyContentItem>
          <PartyContentItemTitle>Date:</PartyContentItemTitle>
          <PartyContentItemText>{party.date}</PartyContentItemText>
        </PartyContentItem>
      </PartyContent>
    </PartyBlock>
  );
};

export default Party;
