import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";
import { Colors } from "../../types/colors";
import { BoardFigureBlock } from "./BoardFigure/BoardFigureStyles";
import Player from "../../types/player";

const BoardBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

interface IBoardContent {
  side: Colors;
}

const BoardContent = styled.div<IBoardContent>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  width: ${pxIntoRem(700)};
  height: ${pxIntoRem(700)};
  flex-shrink: 0;

  ${({ side }) => side === Colors.BLACK && `transform: rotate(180deg);`} // * {
  //   transform: rotate(180deg);
  // }

  // ${BoardFigureBlock} {
  //   transform: rotate(0deg);
  // }

  @media(max-width: 400px) {
    width: 90vw;
    height: 90vw;
  }
`;

// const BoardFrame = styled.img`
//   position: absolute;
//   left: ${pxIntoRem(-70)};
//   top: ${pxIntoRem(-70)};
//   width: ${pxIntoRem(700)};
//   height: ${pxIntoRem(700)};
//   object-fit: contain;
//   pointer-events: none;
// `;

interface IBoardWinner {
  player: Player | null;
}

const BoardWinner = styled.div<IBoardWinner>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999999;

  ${({ player }) => player?.side === Colors.BLACK && `transform: rotate(180deg);`}
`;

const BoardWinnerTitle = styled.h2`
  color: white;
  font-size: ${pxIntoRem(24)};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
`;

const BoardWinnerButton = styled.button`
  cursor: pointer;
  font-size: ${pxIntoRem(20)};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  text-decoration: underline;
  background-color: transparent;
  color: white;
`;

interface IBoardTimer {
  side: Colors;
  player: Player | null;
}

const BoardTimer = styled.div<IBoardTimer>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ab7047;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(55)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // width: ${pxIntoRem(102)};
  // height: ${pxIntoRem(52)};
  padding: 2px;
  border: ${pxIntoRem(1)} solid #ab7047;
  position: absolute;
  white-space: nowrap; /* Запрещает перенос строк */

  ${({ side }) => side === Colors.BLACK && `top: ${pxIntoRem(-130)};`}
  ${({ side }) => side === Colors.BLACK && `left: ${pxIntoRem(0)};`}

  ${({ side }) => side === Colors.WHITE && `bottom: ${pxIntoRem(-130)};`}
  ${({ side }) => side === Colors.WHITE && `right: ${pxIntoRem(0)};`}

  ${({ player }) => player?.side === Colors.BLACK && `transform: rotate(180deg);`}
`;

export { BoardBlock, BoardContent, BoardWinner, BoardWinnerButton, BoardWinnerTitle, BoardTimer };
