import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import WebSocketProvider from "./contexts/websocket";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Coda", system-ui !important;
    font-weight: 800;
    margin: 0;
    padding: 0;
    border: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box!important;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    -webkit-tap-highlight-color: transparent;
  }

  body, html, #root {
    display: flex;
    width: 100%;
    min-height: 100vh;
    font-size: 13px;
    max-width: 1440px;
    margin: 0 auto;

    body{
    position: relative;
      min-width: 100%;
      min-height: 100%;
      overflow-x: hidden;
      background-color: #EFE9D4;
}

    @media(max-width: 1100px) {
      font-size: 11px;
    }

    @media(max-width: 900px) {
      font-size: 10px;
    }

    @media(max-width: 800px) {
      font-size: 9px;
    }

    @media(max-width: 725px) {
      font-size: 8px;
    }

    @media(max-width: 410px) {
    font-size: 7px;
    }

    @media(max-width: 370px) {
      font-size: 6.5px;
    }

    @media(max-width: 360px) {
      font-size: 6px;
    }

    @media(max-width: 320px) {
      font-size: 5.5px;
    }
      @media(max-width: 290px) {
      font-size: 5px;
    }

    @media(max-width: 250px) {
      font-size: 4px;
    }
  }
     button{
      font-family: "Coda", system-ui !important;
      font-weight: 800 !important;
      color: #fff !important;
      text-transform: uppercase !important; 
    }
    // #root{
    // position: relative;
    //   max-width: 450px;
    //   margin: 0 auto;
    // }
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <WebSocketProvider>
      <GlobalStyle />
      <App />
    </WebSocketProvider>
  </BrowserRouter>
);
