/*
 export default {
   apiUrl: "http://localhost:4700",
   websocketUrl: "ws://localhost:4700",
};
*/

export default {
  apiUrl: "https://api.magic-chess.space",
  websocketUrl: "wss://api.magic-chess.space/ws",
};