import React, { FC, useMemo } from "react";
import {
  MechanicModalBlock,
  MechanicModalButton,
  MechanicModalContent,
  MechanicModalImage,
  MechanicModalText,
  MechanicModalTitle,
} from "./MechanicModalStyles";
import { Magic, getMagic } from "../../../../types/magic";

interface IMechanicModal {
  magic: Magic | null;
  onClose: () => void;
}

const MechanicModal: FC<IMechanicModal> = ({ magic, onClose }) => {
  const magicObj = useMemo(() => {
    return getMagic(magic);
  }, [magic]);

  return (
    <MechanicModalBlock onClick={onClose}>
      <MechanicModalContent onClick={(e) => e.stopPropagation()}>
        <MechanicModalImage src={magicObj.image} />
        <MechanicModalTitle>{magicObj.title}</MechanicModalTitle>
        <MechanicModalText>{magicObj.description}</MechanicModalText>
        <MechanicModalButton onClick={onClose}>Close</MechanicModalButton>
      </MechanicModalContent>
    </MechanicModalBlock>
  );
};

export default MechanicModal;
