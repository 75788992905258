import React, { useEffect, useMemo, useState } from "react";
import { NavigationBlock, NavigationButton, NavigationButtonImage, NavigationButtonText } from "./NavigationStyles";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "../../services/authService";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [photo_id, setPhotoId] = useState(""); // Перемещаем useState внутрь компонента

  useEffect(() => {
    const getData = async () => {
      const res = await authService.getUser();
      console.log(res.data.photo_id);
      if (res.data.photo_id != null && res.data.photo_id != "") {
        setPhotoId(res.data.photo_id); // Устанавливаем photo_id после успешного получения данных
      } else {
        setPhotoId("/images/menu_profile.png");
      }

    };
    getData();
  }, []);

  const menuButtons = [
    {
      image: "/images/menu_question.png",
      link: "/faq",
      text: "FAQ",
    },
    {
      image: photo_id, // Теперь photo_id доступен здесь
      link: "/profile",
      text: "Profile",
    },
    {
      image: "/images/menu_logo.png",
      link: "/menu",
      text: "Menu",
    }
  ];

  const profileButtons = [
    {
      image: "/images/menu_question.png",
      link: "/faq",
      text: "FAQ",
    },
    {
      image: photo_id, // И здесь тоже
      link: "/profile",
      text: "Profile",
    },
    {
      image: "/images/menu_logo.png",
      link: "/menu",
      text: "Menu",
    },
  ];

  const buttons = useMemo(() => {
    if (location.pathname.includes("profile")) {
      return profileButtons;
    }
    return menuButtons;
  }, [location, photo_id]); // Добавляем photo_id в зависимости useMemo, чтобы избежать ненужных перерисовок

  return (
      <NavigationBlock>
        {buttons.map((button, idx) => {
          if (idx === 0 || idx === buttons.length - 1) {
            return (
                <NavigationButton onClick={() => navigate(button.link)} key={button.link}>
                  <NavigationButtonImage src={button.image} />
                  <NavigationButtonText>{button.text}</NavigationButtonText> {/* Добавляем текст */}
                </NavigationButton>
            );
          }
          return (
              <React.Fragment key={button.link}>
                <NavigationButton onClick={() => navigate(button.link)}>
                  <NavigationButtonImage src={button.image} />
                  <NavigationButtonText>{button.text}</NavigationButtonText> {/* Добавляем текст */}
                </NavigationButton>
              </React.Fragment>
          );
        })}
      </NavigationBlock>
  );
};

export default Navigation;
