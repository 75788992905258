import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";

const PartyBlock = styled.button`
  display: flex;
  border: ${pxIntoRem(1)} solid rgb(0, 0, 0);
  border-radius: ${pxIntoRem(10)};
  background-color: rgb(248, 243, 227);
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  max-width: 700px;
  justify-content: center;
  align-items: center;
`;

const PartyImage = styled.img`
  // width: ${pxIntoRem(200)};
  // height: ${pxIntoRem(200)};
  width: 100%;
  max-width: ${pxIntoRem(500)};
  height: 100%;
  max-height: ${pxIntoRem(500)};
  flex-basis: 60%;
  object-fit: cover;

  @media(max-width: 769px) {
  max-width: ${pxIntoRem(300)};
  max-height: ${pxIntoRem(300)};
  }

  @media(max-width: 560px) {
  max-width: ${pxIntoRem(250)};
  max-height: ${pxIntoRem(250)};
  }

  @media(max-width: 510px) {
  max-width: ${pxIntoRem(220)};
  max-height: ${pxIntoRem(220)};
  }

  @media(max-width: 450px) {
  max-width: ${pxIntoRem(200)};
  max-height: ${pxIntoRem(200)};
  }

  @media(max-width: 410px) {
  max-width: ${pxIntoRem(160)};
  max-height: ${pxIntoRem(160)};
  }
`;

const PartyContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-evenly;
  justify-content: center;
  height: 100%;
  text-align: center;
  flex-grow: 1;
  gap: ${pxIntoRem(30)};
  flex-basis: 50%;
  margin: auto 10px;
  @media(max-width: 560px) {
    gap: ${pxIntoRem(15)};
  }
`;

const PartyContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(34)};
    @media(max-width: 560px) {
      gap: ${pxIntoRem(20)};
    }
`;

const PartyContentItemTitle = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(50)};
  font-weight: 400;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
    @media(max-width: 769px) {
      font-size: ${pxIntoRem(40)};
    }
    @media(max-width: 560px) {
    font-size: ${pxIntoRem(30)};
    }
    @media(max-width: 500px) {
    font-size: ${pxIntoRem(27)};
    }

    @media(max-width: 450px) {
    font-size: ${pxIntoRem(20)};
    }

    @media(max-width: 410px) {
    font-size: ${pxIntoRem(17)};
    }
`;

const PartyContentItemText = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(35)};
  font-weight: 700;
  line-height: ${pxIntoRem(18)};
  letter-spacing: 0%;
    @media(max-width: 769px) {
      font-size: ${pxIntoRem(25)};
    }
    @media(max-width: 560px) {
    font-size: ${pxIntoRem(20)};
    }
    @media(max-width: 450px) {
    font-size: ${pxIntoRem(18)};
    }
`;

const PartyContentHeart = styled.img`
  position: absolute;
  right: ${pxIntoRem(7)};
  top: -${pxIntoRem(60)};
  height: ${pxIntoRem(30)};
  cursor: pointer;
   @media(max-width: 769px) {
    top: -${pxIntoRem(40)};
    }
  @media(max-width: 500px) {
    height: ${pxIntoRem(23)};
    }
    @media(max-width: 450px) {
    top: -${pxIntoRem(10)};
    right: ${pxIntoRem(-4)};
    }
    /*
    @media(max-width: 450px) {
    right: ${pxIntoRem(-4)};
    }
    */
`;

export {
  PartyBlock,
  PartyContent,
  PartyContentItem,
  PartyContentItemText,
  PartyContentItemTitle,
  PartyImage,
  PartyContentHeart,
};
