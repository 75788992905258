import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const MechanicsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const MechanicsTitle = styled.h1`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  margin: 0 auto;
  margin-top: ${pxIntoRem(44)};
`;

const MechanicsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(200)};
  height: ${pxIntoRem(66)};
  border-radius: ${pxIntoRem(50)};
  background-color: #FFC700;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  margin: 0 auto;
  margin-top: ${pxIntoRem(40)};
  cursor: pointer;
`;

const MechanicsText = styled.p`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 400;
  line-height: ${pxIntoRem(13)};
  letter-spacing: 0%;
  text-align: center;
  margin-top: ${pxIntoRem(16)};
`;

const MechanicsChosenMechanics = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${pxIntoRem(50)};
  width: 100%;
  margin-top: ${pxIntoRem(35)};
  margin-left: 10px;
`;

const MechanicsChosenMechanic = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${pxIntoRem(320)};
  height: ${pxIntoRem(80)};
  padding: ${pxIntoRem(15)} ${pxIntoRem(20)};
  box-shadow: -${pxIntoRem(1)} -${pxIntoRem(1)} ${pxIntoRem(10)} ${pxIntoRem(0)} rgba(0, 0, 0, 0.25),
    ${pxIntoRem(1)} ${pxIntoRem(1)} ${pxIntoRem(10)} ${pxIntoRem(0)} rgba(0, 0, 0, 0.25);
  background-color: rgb(248, 243, 227);
  cursor: pointer;
  // flex: 1 1 auto;
  // background-color: blue;

   @media(max-width: 700px) {
      max-width: ${pxIntoRem(290)};
    }

     @media(max-width: 600px) {
      max-width: ${pxIntoRem(270)};
    }

    @media(max-width: 500px) {
      max-width: ${pxIntoRem(255)};
    }
`;

const MechanicsChosenMechanicImage = styled.img`
  height: ${pxIntoRem(50)};
  object-fit: cover;
`;

const MechanicsChosenMechanicTitle = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(25)};
  font-weight: 500;
  line-height: ${pxIntoRem(28)};
  letter-spacing: 0%;
  text-align: left;
  margin-left: ${pxIntoRem(22)};
`;

const MechanicsChosenMechanicCheck = styled.img`
  width: ${pxIntoRem(16)};
  margin-left: ${pxIntoRem(22)};
`;

const MechanicsChosenMechanicBook = styled.img`
  position: absolute;
  right: ${pxIntoRem(10)};
  top: ${pxIntoRem(10)};
  width: ${pxIntoRem(18.74)};
  height: ${pxIntoRem(16)};
  cursor: pointer;
`;

const MechanicsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${pxIntoRem(100)};
  width: 100%;
  margin-top: ${pxIntoRem(60)};
  margin-bottom: ${pxIntoRem(55)};
`;

const MechanicsListItem = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(75)};
  height: ${pxIntoRem(75)};
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(85)};
  background: rgb(255, 255, 255);
`;

const MechanicsListItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(69)};
  height: ${pxIntoRem(69)};
  border: ${pxIntoRem(1)} solid rgb(134, 101, 81);
  border-radius: ${pxIntoRem(77)};
  background: rgb(255, 255, 255);
`;

const MechanicsListItemImage = styled.img`
  height: ${pxIntoRem(50)};
  object-fit: contain;
  max-width: 75%;
`;

const MechanicsListItemBook = styled.div`
  position: absolute;
  right: -${pxIntoRem(8)};
  top: -${pxIntoRem(12)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxIntoRem(30)};
  height: ${pxIntoRem(30)};
  border-radius: ${pxIntoRem(30)};
  background-color: #858585;
`;

const MechanicsListItemBookImage = styled.img`
  width: ${pxIntoRem(19.74)};
  height: ${pxIntoRem(16)};
`;

export {
  MechanicsBlock,
  MechanicsButton,
  MechanicsText,
  MechanicsTitle,
  MechanicsChosenMechanics,
  MechanicsChosenMechanic,
  MechanicsChosenMechanicBook,
  MechanicsChosenMechanicCheck,
  MechanicsChosenMechanicImage,
  MechanicsChosenMechanicTitle,
  MechanicsList,
  MechanicsListItem,
  MechanicsListItemBook,
  MechanicsListItemBookImage,
  MechanicsListItemContent,
  MechanicsListItemImage,
};
