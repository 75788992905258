import { FC } from "react";
import {
  BoardFigureTransformBlock,
  BoardFigureTransformButton,
  BoardFigureTransformButtonContent,
  BoardFigureTransformButtonImage,
  BoardFigureTransformContent,
} from "./BoardFigureTransformStyles";
import { Figures } from "../../../../types/figure";
import pxIntoRem from "../../../../utils/pxIntoRem";
import { Colors } from "../../../../types/colors";

interface IBoardFigureTransform {
  onClick(figure: Figures): any;
  figures: Figures[];
  color: Colors;
  left: string;
  bottom: string;
}

const BoardFigureTransform: FC<IBoardFigureTransform> = ({ onClick, figures, color, left, bottom }) => {
  return (
    <BoardFigureTransformBlock left={left} bottom={bottom} side={color}>
      <BoardFigureTransformContent>
        {figures.map((figure) => {
          return (
            <BoardFigureTransformButton key={figure} onClick={() => onClick(figure)}>
              <BoardFigureTransformButtonContent>
                <BoardFigureTransformButtonImage
                  src={`/images/figures/${color === Colors.WHITE ? "w" : ""}${figure}.png`}
                />
              </BoardFigureTransformButtonContent>
            </BoardFigureTransformButton>
          );
        })}
      </BoardFigureTransformContent>
    </BoardFigureTransformBlock>
  );
};

export default BoardFigureTransform;
