import React, { FC, useEffect, useMemo, useState } from "react";
import {
  MenuPrivateLaunchButton,
  MenuPrivateLinkBlock,
  MenuPrivateLinkButton,
  MenuPrivateLinkContent,
  MenuPrivateLinkField,
  MenuPrivateLinkFieldContent,
  MenuPrivateLinkLogo,
  MenuPrivateLinkText,
  MenuPrivateLinkTitle,
} from "./MenuPrivateLinkStyles";
import Clipboard from "../../../utils/clipboard";
import authService from "../../../services/authService";
import { useLocation, useNavigate } from "react-router-dom";

interface IMenuPrivateLink {
  boardId: string | null;
  onClose(): void;
}

const MenuPrivateLink: FC<IMenuPrivateLink> = ({ onClose, boardId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [referalCode, setReferalCode] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const res = await authService.getUser();
      const referal = res.data.ref_code;
      setReferalCode(referal); // Сохраняем реферальный код
    };

    fetchUser();
  }, []);

  const link = useMemo(() => {
    return `http://t.me/magic_chess_auth_bot/myapp?startapp=${referalCode}_${boardId}`; // Добавляем реферальный код в URL
  }, [boardId, referalCode]);



  const copyLink = () => {
    if (!link) {
      return;
    }

    Clipboard.copy(link);
    setCopied(true);
  };

  const handlePlayClick = () => {
    navigate(`/board/${boardId}`);
  };

  return (
      <MenuPrivateLinkBlock onClick={onClose}>
        <MenuPrivateLinkContent onClick={(e) => e.stopPropagation()}>
          <MenuPrivateLinkLogo alt="logo" src="/images/logo.png" />
          <MenuPrivateLinkTitle>Your link</MenuPrivateLinkTitle>
          <MenuPrivateLinkText>*Copy and share it with a friend</MenuPrivateLinkText>
          <MenuPrivateLinkField to={link}>
            <MenuPrivateLinkFieldContent>
              {link.slice(0, 30)}...
            </MenuPrivateLinkFieldContent>
          </MenuPrivateLinkField>
          <MenuPrivateLinkButton onClick={copyLink}>{copied ? "Copied!" : "Copy"}</MenuPrivateLinkButton>
          <MenuPrivateLaunchButton onClick={handlePlayClick}>Play</MenuPrivateLaunchButton>
        </MenuPrivateLinkContent>
      </MenuPrivateLinkBlock>
  );
};

export default MenuPrivateLink;