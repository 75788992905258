class Clipboard {
  private static fallback(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.display = "none";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
    } catch (err) {}

    document.body.removeChild(textArea);
  }

  public static copy(text: string) {
    if (!navigator.clipboard) {
      Clipboard.fallback(text);
      return;
    }

    navigator.clipboard.writeText(text).catch((err) => {});
  }
}

export default Clipboard;
