import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const LoginBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const LoginContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${pxIntoRem(670)};
  height: ${pxIntoRem(820)};
  flex-shrink: 0;
  border-radius: ${pxIntoRem(110)};
  border: ${pxIntoRem(2)} solid #ad7e5c;
  padding-block: ${pxIntoRem(54)} ${pxIntoRem(66)};
`;

const LoginLogo = styled.img`
  width: ${pxIntoRem(78.9)};
  height: ${pxIntoRem(80)};
  flex-shrink: 0;
`;

const LoginTitle = styled.h1`
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: ${pxIntoRem(41)};
`;

const LoginInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(55)};
  margin-top: ${pxIntoRem(65)};
`;

const LoginInput = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${pxIntoRem(400)};
`;

const LoginInputTitle = styled.label`
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: ${pxIntoRem(15)};
`;

const LoginInputFieldWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  position: relative;
  margin-top: ${pxIntoRem(10)};

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: ${pxIntoRem(1)} solid #ad7e5c;
    border-radius: ${pxIntoRem(70)};
    pointer-events: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 98%;
    height: 92%;
    border: ${pxIntoRem(1)} solid #ad7e5c;
    border-radius: ${pxIntoRem(70)};
    pointer-events: none;
  }
`;

const LoginInputField = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(25)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
  border-radius: ${pxIntoRem(40)};
  width: ${pxIntoRem(400)};
  height: ${pxIntoRem(80)};
  flex-shrink: 0;
  background-color: transparent;
  position: relative;
  padding: ${pxIntoRem(0)} ${pxIntoRem(25)};
`;

const LoginButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${pxIntoRem(0)} ${pxIntoRem(86)} ${pxIntoRem(0)} ${pxIntoRem(78)};
  margin-top: auto;
`;

const LoginTextButton = styled.button`
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background-color: transparent;
  cursor: pointer;
`;

const LoginButton = styled.button`
  display: inline-flex;
  justify-content: flex-end;
  padding: ${pxIntoRem(15.5)} ${pxIntoRem(36)} ${pxIntoRem(12.5)} ${pxIntoRem(40)};
  align-items: center;
  color: #000;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: ${pxIntoRem(30)};
  background: #ad7e5c;
  cursor: pointer;
`;

export {
  LoginBlock,
  LoginContent,
  LoginTitle,
  LoginLogo,
  LoginInput,
  LoginInputTitle,
  LoginInputField,
  LoginInputFieldWrapper,
  LoginInputs,
  LoginButtons,
  LoginTextButton,
  LoginButton,
};
