import React, { useEffect } from "react";
import {
    LoginBlock,
    LoginContent,
    LoginLogo,
} from "../Login/LoginStyles";
import {
    LoginButton
} from "./TgLoginStyles"
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "../../../services/authService";
import usersService from "../../../services/usersService";

function generateRefCode(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

function TgLogin() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const login = searchParams.get("login");
        const email = searchParams.get("email");
        const password = searchParams.get("password");
        const photo_id = searchParams.get("photo_id");
        const bonus = 100;
        const ref_code = generateRefCode();


        if (login && email && password && photo_id && bonus) {
            onLogin(); // Call onRegistration immediately
        }
    }, [searchParams]);

    async function onLogin() {
        let login = searchParams.get("login") || "";
        let email = searchParams.get("email") || "";
        let photo_id = searchParams.get("photo_id") || "";
        const password = searchParams.get("password") || "";
        const bonus = 100;
        const ref_code = generateRefCode();
        const param = searchParams.get("telegram_id") ?? "0"; // значение по умолчанию
        const telegram_id = parseInt(param, 10);

        if (login === "undefined") {
            login = "Player" + telegram_id;
            email = "t.me/" + login;
        }

        if (photo_id === "undefined") {
            photo_id = "";
        }

        const resp = await authService.registration({
            login,
            email,
            password,
            photo_id,
            bonus,
            ref_code,
            telegram_id,
        });

        if (resp.status === "success") {
            usersService.saveUser(resp.data);

            navigate("/menu");
        }

        const res = await authService.loginByTg({
            telegram_id,
            password,
            photo_id,
        });

        if (res.status === "success") {
            usersService.saveUser(res.data);

            navigate("/menu");
        }
    }



    return (
        <LoginBlock>
        </LoginBlock>
    );
}

export default TgLogin;
