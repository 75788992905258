import React, { useEffect, useState } from "react";
import { LoginBlock } from "../Login/LoginStyles";
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "../../../services/authService";
import usersService from "../../../services/usersService";
import axios, { AxiosResponse } from "axios";

const BOT_TOKEN = '7156206892:AAEQ4LoLxdmt5HuG82v_qDaazLKd23FikPs';

function getAllParams(jsonString: string) {
    const cleanJsonString = jsonString.replace(/&[^&]+$/, '');

    try {
        return JSON.parse(cleanJsonString);
    } catch (error) {
        console.error("Ошибка при парсинге JSON:", error);
        return null;
    }
}

function generateRefCode(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

async function inviteUserSearch(referal: string) {
    const inviteUser = await authService.getUserByRefCode(referal);
    if (inviteUser.status === "success") {
        const newBonus = inviteUser.data.data.bonus + 10;
        await authService.updateUserBonus({
            id: inviteUser.data.data.id,
            bonus: newBonus,
        });
    }
}

function ReferalAuth() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchUserData = async () => {
            const hashParams = new URLSearchParams(window.location.hash.replace('#', '?'));

            const userData = hashParams.get('tgWebAppData');
            if (userData) {
                const decodedData = decodeURIComponent(userData.split('=')[1]);
                const result = getAllParams(decodedData);

                if (result) {
                    console.log("\nПолученные данные пользователя:");
                    console.log("ID:", result.id);
                    console.log("Имя:", result.first_name);
                    console.log("Фамилия:", result.last_name || '(не указана)');
                    console.log("Username:", result.username);
                    console.log("Язык:", result.language_code);
                    console.log("Разрешение на написание в PM:", result.allows_write_to_pm);
                    let login = result.username;
                    let email = `t.me/${login}`;
                    const password = "telegram";
                    const telegram_id = result.id;

                    if (login === "undefined" || login === undefined) {
                        login = "Player" + telegram_id;
                        email = "t.me/" + login;
                    }



                    try {
                        const response: AxiosResponse = await axios.get(`https://api.telegram.org/bot${BOT_TOKEN}/getUserProfilePhotos?user_id=${result.id}`);
                        if (response.data.ok) {
                            const photos = response.data.result.photos;
                            let photo_id: string = "";

                            if (photos.length > 0) {
                                const fileId = photos[0][photos[0].length - 1].file_id;

                                // Получаем файл аватара
                                const fileResponse: AxiosResponse = await axios.get(`https://api.telegram.org/bot${BOT_TOKEN}/getFile?file_id=${fileId}`);
                                const filePath = fileResponse.data.result.file_path;
                                photo_id = `https://api.telegram.org/file/bot${BOT_TOKEN}/${filePath}`; // Сохраняем URL аватарки
                            } else {
                                photo_id = "";
                            }

                            if (photo_id === "undefined") {
                                photo_id = "";
                            }

                            await onLogin(login, email, password, photo_id, telegram_id); // Передаем пустую строку, если photo_id пуст
                        }
                    } catch (error) {
                        console.error("Ошибка при получении аватара:", error);
                    }
                } else {
                    console.log("Не удалось получить данные пользователя.");
                }
            }
            setLoading(false);
        };

        fetchUserData();
    }, [searchParams]);

    async function onLogin(login: string, email: string, password: string, photo_id: string, telegram_id: number) {
        const ref_code = generateRefCode();
        const ref = searchParams.get("tgWebAppStartParam") || "";
        const [referal, gameId] = ref.split('_');
        console.log(referal);

        try {
            const resp = await authService.registration({
                login,
                email,
                password,
                photo_id, // Здесь используем photo_id
                bonus: 100,
                ref_code,
                telegram_id,
            });

            if (resp.status === "success") {
                usersService.saveUser(resp.data);
                await inviteUserSearch(referal);
                navigate(`/board/${gameId}`);
            }

            const res = await authService.loginByTg({
                telegram_id,
                password,
                photo_id, // Здесь также используем photo_id
            });

            if (res.status === "success") {
                usersService.saveUser(res.data);
                navigate(`/board/${gameId}`);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <LoginBlock>
            {loading ? <p>Загрузка...</p> : <p>Готово!</p>}
        </LoginBlock>
    );
}

export default ReferalAuth;