import React, { useState } from "react";
import {
  LoginBlock,
  LoginButton,
  LoginButtons,
  LoginContent,
  LoginInput,
  LoginInputField,
  LoginInputFieldWrapper,
  LoginInputTitle,
  LoginInputs,
  LoginLogo,
  LoginTextButton,
  LoginTitle,
} from "./LoginStyles";
import { useNavigate } from "react-router-dom";
import authService from "../../../services/authService";
import usersService from "../../../services/usersService";

function Login() {
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const photo_id = "";
  async function onLogin() {
    const res = await authService.login({
      login,
      password,
      photo_id,
    });

    if (res.status === "success") {
      usersService.saveUser(res.data);

      navigate("/menu");
    }
  }

  return (
    <LoginBlock>
      <LoginContent>
        <LoginLogo alt="logo" src="/images/logo.png" />
        <LoginTitle>Вход</LoginTitle>

        <LoginInputs>
          <LoginInput>
            <LoginInputTitle>Логин или эл.почта</LoginInputTitle>
            <LoginInputFieldWrapper>
              <LoginInputField value={login} onChange={(e) => setLogin(e.currentTarget.value)} />
            </LoginInputFieldWrapper>
          </LoginInput>

          <LoginInput>
            <LoginInputTitle>Пароль</LoginInputTitle>
            <LoginInputFieldWrapper>
              <LoginInputField value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
            </LoginInputFieldWrapper>
          </LoginInput>
        </LoginInputs>

        <LoginButtons>
          <LoginTextButton onClick={() => navigate("/auth/registration")}>Создать аккаунт</LoginTextButton>
          <LoginButton onClick={onLogin}>Войти</LoginButton>
        </LoginButtons>
      </LoginContent>
    </LoginBlock>
  );
}

export default Login;
