import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";

const PartiesSelectBlock = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(40)};
  background-color: rgb(248, 243, 227);
  width: 100%;
  max-width: ${pxIntoRem(400)};
  height: ${pxIntoRem(72.97)};
  margin-top: ${pxIntoRem(8)};
  cursor: pointer;
`;

const PartiesSelectContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${pxIntoRem(40)};
  width: 100%;
  max-width: ${pxIntoRem(390)};
  // height: ${pxIntoRem(63)};
  background-color: transparent;
  padding: ${pxIntoRem(0)} ${pxIntoRem(20)} ${pxIntoRem(0)} ${pxIntoRem(25)};
`;

const PartiesSelectText = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(30)};
  font-weight: 700;
  line-height: ${pxIntoRem(33)};
  letter-spacing: 0%;
  text-align: left;
`;

const PartiesSelectArrow = styled.img`
  height: ${pxIntoRem(14)} ${pxIntoRem(4)};
  width: ${pxIntoRem(25)};
`;

const PartiesSelectValues = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const PartiesSelectValuesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pxIntoRem(50)};
  gap: ${pxIntoRem(35)};
  border-radius: ${pxIntoRem(100)};
  background-color: rgb(248, 243, 227);
  max-width: ${pxIntoRem(703)};
  max-height: ${pxIntoRem(295)};
  flex-grow: 1;
  overflow-y: scroll;
`;

const PartiesSelectValuesItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(40)};
  background-color: rgb(248, 243, 227);
  width: ${pxIntoRem(400)};
  height: ${pxIntoRem(80)};
  cursor: pointer;
  flex-shrink: 0;
`;

const PartiesSelectValuesItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${pxIntoRem(40)};
  width: ${pxIntoRem(390)};
  height: ${pxIntoRem(70)};
  background-color: transparent;
  padding: ${pxIntoRem(0)} ${pxIntoRem(20)};
  flex-shrink: 0;
`;

const PartiesSelectValuesItemText = styled.span`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(30)};
  font-weight: 700;
  line-height: ${pxIntoRem(33)};
  letter-spacing: 0%;
  text-align: left;
`;

const PartiesSelectValuesItemCheck = styled.img`
  width: ${pxIntoRem(40)};
  height: ${pxIntoRem(40)};
  position: absolute;
  right: -${pxIntoRem(50)};
`;

export {
  PartiesSelectBlock,
  PartiesSelectContent,
  PartiesSelectText,
  PartiesSelectArrow,
  PartiesSelectValues,
  PartiesSelectValuesContent,
  PartiesSelectValuesItem,
  PartiesSelectValuesItemContent,
  PartiesSelectValuesItemText,
  PartiesSelectValuesItemCheck,
};
