import React, { FC, useState } from "react";
import {
  MenuSettingsBlock,
  MenuSettingsContent,
  MenuSettingsContentItems,
  MenuSettingsContentItem,
  MenuSettingsLogo,
  MenuSettingsTitle,
  MenuSettingsContentItemTitle,
  MenuSettingsContentItemRange,
  MenuSettingsContentItemRangeInput,
  MenuSettingsContentItemButton,
} from "./MenuSettingsStyles";

interface IMenuSettings {
  onClick(initialSeconds: number, secondsAdding: number): any;
  onClose(): any;
}

const MenuSettings: FC<IMenuSettings> = ({ onClick, onClose }) => {
  const [minuts, setMinuts] = useState(10);
  const [seconds, setSeconds] = useState(3);

  return (
    <MenuSettingsBlock onClick={onClose}>
      <MenuSettingsContent onClick={(e) => e.stopPropagation()}>
        <MenuSettingsLogo alt="logo" src="/images/logo.png" />

        <MenuSettingsTitle>Game Settings</MenuSettingsTitle>

        <MenuSettingsContentItems>
          <MenuSettingsContentItem>
            <MenuSettingsContentItemTitle>
              Minutes per game:&nbsp;
              <span>{minuts}</span>
            </MenuSettingsContentItemTitle>

            <MenuSettingsContentItemRange>
              <MenuSettingsContentItemRangeInput type={"range"} value={minuts} min={2} max={60} onChange={(e) => setMinuts(Number(e.currentTarget.value))} step={1} />
            </MenuSettingsContentItemRange>
          </MenuSettingsContentItem>

          <MenuSettingsContentItem>
            <MenuSettingsContentItemTitle>
              Seconds after move:&nbsp;
              <span>{seconds}</span>
            </MenuSettingsContentItemTitle>

            <MenuSettingsContentItemRange>
              <MenuSettingsContentItemRangeInput type={"range"} value={seconds} min={1} max={30} onChange={(e) => setSeconds(Number(e.currentTarget.value))} step={1} />
            </MenuSettingsContentItemRange>
          </MenuSettingsContentItem>

          <MenuSettingsContentItem>
            <MenuSettingsContentItemButton onClick={() => onClick(minuts * 60, seconds)}>Apply</MenuSettingsContentItemButton>
          </MenuSettingsContentItem>
        </MenuSettingsContentItems>
      </MenuSettingsContent>
    </MenuSettingsBlock>
  );
};

export default MenuSettings;
