import {
  PartiesBlock,
  PartiesButtons,
  PartiesList,
  PartiesSelectBlock,
  PartiesSelectTitle,
  PartiesSelects,
  PartiesTitle,
} from "./PartiesStyles";
import { useNavigate } from "react-router-dom";
import { useWebsocketEventDispatch } from "../../../hooks/useWebsocketEvent";
import Navigation from "../../../components/Navigation/Navigation";
import PartiesSelect from "./PartiesSelect/PartiesSelect";
import ProfileButtons from "../components/ProfileButtons/ProfileButtons";
import { useEffect, useMemo, useState } from "react";
import Party from "./Party/Party";
import historyService from "../../../services/historyService";
import { BoardHistory, BoardHistoryItem } from "../../../types/boardHistory";

const buttons = [
  {
    image: {
      src: "/images/main.png",
      width: 75.07,
      height: 75,
    },
    text: "Главная",
    link: "/profile",
  },
  {
    image: {
      src: "/images/personal.png",
      width: 75,
      height: 75,
    },
    text: "Личные данные",
    link: "/profile/personal",
  },
  {
    image: {
      src: "/images/mechanics.png",
      width: 83.87,
      height: 75,
    },
    text: "Выбор механик",
    link: "/profile/mechanics",
  },
];

function Parties() {
  const navigate = useNavigate();
  const dispatch = useWebsocketEventDispatch();

  const [parties, setPatries] = useState<BoardHistoryItem[]>([]);

  const getData = async () => {
    const res = await historyService.getHistories();
    if (res.status === "success") {
      setPatries(res.data.reverse());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [isWin, setIsWin] = useState<boolean | null>(null);
  const [isFavourite, setIsFavourite] = useState<boolean | null>(null);
  const [date, setDate] = useState<string | null>(null);

  const correctData = useMemo(() => {
    let result = [...parties];

    if (isWin !== null) {
      result = result.filter((item) => item.isWin === isWin);
    }

    if (isFavourite) {
      result = result.filter((item) => item.isFavourite === isFavourite);
    }

    if (date !== null) {
      result = result.filter((item) => item.date === date);
    }

    return result;
  }, [parties, isWin, isFavourite, date]);

  return (
    <PartiesBlock>


      <PartiesTitle>View parties</PartiesTitle>

      <PartiesSelects>
        <PartiesSelectBlock>
          <PartiesSelectTitle>Result</PartiesSelectTitle>

          <PartiesSelect
            onChange={setIsWin}
            value={isWin}
            values={[null, true, false]}
            formatValue={(value) => {
              switch (value) {
                case true:
                  return "Win!";
                case false:
                  return "Lose(";
                default:
                  return "-";
              }
            }}
          />
        </PartiesSelectBlock>

        <PartiesSelectBlock>
          <PartiesSelectTitle>Game date</PartiesSelectTitle>
          <PartiesSelect
            onChange={setDate}
            value={date}
            values={[null, ...new Set(parties.map((party) => party.date))]}
            formatValue={(value) => (value ? value : "-")}
          />
        </PartiesSelectBlock>

        <PartiesSelectBlock>
          <PartiesSelectTitle>Favourite</PartiesSelectTitle>

          <PartiesSelect
            onChange={setIsFavourite}
            value={isFavourite}
            values={[true, false]}
            formatValue={(value) => (value ? "Only them" : "All")}
          />
        </PartiesSelectBlock>
      </PartiesSelects>

      <PartiesList>
        {correctData.map((party) => {
          return <Party party={party} key={party.id} onChange={getData} />;
        })}
      </PartiesList>
      <Navigation />
    </PartiesBlock>
  );
}

export default Parties;
