import styled from "styled-components";
import { Colors } from "../../../types/colors";
import pxIntoRem from "../../../utils/pxIntoRem";

interface IBoardFigure {
  isInCheck?: boolean;
  side: Colors;
}

const BoardFigureBlock = styled.div<IBoardFigure>`
  position: absolute;
  width: 12.5%;
  height: 12.5%;
  padding: ${pxIntoRem(6)};
  transition: all 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  img {
    width: 70%;
    transition: all 0.5s;
    z-index: 99999999;
  }

  &:hover img {
    z-index: 99999;
    transform: scale(1.06) ${({ side }) => side === Colors.BLACK && `, rotate(180deg);`};
  }

  ${({ side }) =>
    side === Colors.BLACK &&
    `* {
  transform: rotate(180deg);
}`}

  ${({ isInCheck }) => (isInCheck ? `background-color: red;` : "")}
`;

export { BoardFigureBlock };
