import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";
import { Link } from "react-router-dom";

const MenuPrivateLinkBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const MenuPrivateLinkContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${pxIntoRem(800)};
  // height: ${pxIntoRem(444)};
  padding: ${pxIntoRem(58)} ${pxIntoRem(135)} ${pxIntoRem(58)} ${pxIntoRem(135)};
  border-radius: ${pxIntoRem(100)};
  background: #F8F3E3;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const MenuPrivateLinkLogo = styled.img`
  width: ${pxIntoRem(78.9)};
  height: ${pxIntoRem(80)};
  flex-shrink: 0;
`;

const MenuPrivateLinkTitle = styled.h2`
  line-height: normal;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(20)};
`;

const MenuPrivateLinkText = styled.p`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(21)};
  font-weight: 400;
  line-height: ${pxIntoRem(13)};
  letter-spacing: 0%;
  text-align: left;
`;

const MenuPrivateLinkField = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(40)};
  width: ${pxIntoRem(370)};
  height: ${pxIntoRem(60)};
  margin-top: ${pxIntoRem(34)};
  flex-shrink: 0;
`;

const MenuPrivateLinkFieldContent = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: ${pxIntoRem(40)};
  width: ${pxIntoRem(360.75)};
  height: ${pxIntoRem(52.5)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 400;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  flex-shrink: 0;
  padding: 0px ${pxIntoRem(16)};
`;

const MenuPrivateLinkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(30)};
  background: #FFC700;
  border: 1px solid #B18A00;
  width: ${pxIntoRem(250)};
  height: ${pxIntoRem(50)};
  margin-top: ${pxIntoRem(20)};
  flex-shrink: 0;
  cursor: pointer;
`;

const MenuPrivateLaunchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(20)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(30)};
  background: #FFC700;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: ${pxIntoRem(250)};
  height: ${pxIntoRem(50)};
  margin-top: ${pxIntoRem(10)};
  flex-shrink: 0;
  cursor: pointer;
`;

export {
  MenuPrivateLinkBlock,
  MenuPrivateLinkContent,
  MenuPrivateLinkLogo,
  MenuPrivateLinkTitle,
  MenuPrivateLinkText,
  MenuPrivateLinkField,
  MenuPrivateLinkFieldContent,
  MenuPrivateLinkButton,
  MenuPrivateLaunchButton,
};
