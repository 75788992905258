import React, { FC, useState } from "react";
import {
  PartiesSelectArrow,
  PartiesSelectBlock,
  PartiesSelectContent,
  PartiesSelectText,
  PartiesSelectValues,
  PartiesSelectValuesContent,
  PartiesSelectValuesItem,
  PartiesSelectValuesItemCheck,
  PartiesSelectValuesItemContent,
  PartiesSelectValuesItemText,
} from "./PartiesSelectStyles";

interface IPartiesSelect {
  value: any;
  values: any[];
  formatValue?: (value: any) => string;
  onChange: (value: any) => void;
}

const PartiesSelect: FC<IPartiesSelect> = ({ value, values, formatValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const format = (value: string) => (formatValue ? formatValue(value) : value);

  // const ref = useOutsideClick(() => {
  //   setIsOpen(false);
  // });

  const onSelectChange = (value: any) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <>
      <PartiesSelectBlock onClick={() => setIsOpen(true)}>
        <PartiesSelectContent>
          <PartiesSelectText>{format(value)}</PartiesSelectText>
          <PartiesSelectArrow src="/images/arrow.svg" />
        </PartiesSelectContent>
      </PartiesSelectBlock>

      {isOpen && values.length !== 0 && (
        <PartiesSelectValues onClick={() => setIsOpen(false)}>
          <PartiesSelectValuesContent onClick={(e) => e.stopPropagation()}>
            {values.map((valueItem) => {
              return (
                <PartiesSelectValuesItem key={valueItem} onClick={() => onSelectChange(valueItem)}>
                  <PartiesSelectValuesItemContent>
                    <PartiesSelectValuesItemText>{format(valueItem)}</PartiesSelectValuesItemText>
                    {format(valueItem) === format(value) && <PartiesSelectValuesItemCheck src="/images/check.svg" />}
                  </PartiesSelectValuesItemContent>
                </PartiesSelectValuesItem>
              );
            })}
          </PartiesSelectValuesContent>
        </PartiesSelectValues>
      )}
    </>
  );
};

export default PartiesSelect;
