import React, { FC, useEffect } from "react";
import styles from "./Cell.module.scss";
import { Colors } from "../../../types/colors";
import { BoardCellBlock, BoardCellCircle } from "./BoardCellStyles";
import { Figure, Figures } from "../../../types/figure";

interface CellProps {
  color: Colors;
  x: number;
  y: number;
  figure: Figure | null;
  onClick?: (x: number, y: number) => void;
  isAvailableForMove?: boolean;
  isHavingFigure?: boolean;
  isCasting?: boolean;
  isEatable?: boolean;
  speededUpFigureStepsCounter: {
    [Colors.BLACK]: number;
    [Colors.WHITE]: number;
  };
  defendFigureStepsCounter: {
    [Colors.BLACK]: number;
    [Colors.WHITE]: number;
  };
}

const BoardCell: FC<CellProps> = ({
  color,
  x,
  y,
  onClick,
  figure,
  isAvailableForMove,
  isCasting,
  isHavingFigure,
  isEatable,
  speededUpFigureStepsCounter,
  defendFigureStepsCounter,
}: CellProps) => {
  return (
    <BoardCellBlock
      onClick={() => onClick && onClick(x, y)}
      color={color}
      isCasting={isCasting}
      isAvailable={isAvailableForMove && !isHavingFigure}
      isDefended={!isEatable && !!figure?.isDefended}
      isSecondlyDefended={!isEatable && !!figure?.isDefended && defendFigureStepsCounter[figure?.color] === 1}
      isFrozen={!isEatable && !!figure?.isFrozen}
      isSpeededUp={!isEatable && !!figure?.isSpeededUp}
      isSecondlySpeededUp={!isEatable && !!figure?.isSpeededUp && speededUpFigureStepsCounter[figure?.color] === 1}
      isEatable={isEatable}
    >
      <BoardCellCircle />
    </BoardCellBlock>
  );
};

export default BoardCell;
