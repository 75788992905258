import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";
import { Colors } from "../../../types/colors";
import Player from "../../../types/player";

const BoardEndBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999999999;
`;

interface IBoardEndContent {
  player: Player | null;
}

const BoardEndContent = styled.div<IBoardEndContent>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pxIntoRem(95)};
  background: rgb(248, 243, 227);
  width: ${pxIntoRem(650)};
  height: ${pxIntoRem(342)};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ player }) => player?.side === Colors.BLACK && `transform: rotate(180deg);`}
`;

const BoardEndLogo = styled.img`
  width: ${pxIntoRem(78.9)};
  height: ${pxIntoRem(80)};
  flex-shrink: 0;
`;

const BoardEndTitle = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(20)};
`;

const BoardEndButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${pxIntoRem(50)};
  gap: ${pxIntoRem(50)};
`;

const BoardEndButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(220)};
  height: ${pxIntoRem(50)};
  border: 1px solid #B18A00;
  background: #FFC700;
  border-radius: ${pxIntoRem(30)};
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(15)};
  font-weight: 700;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  cursor: pointer;

  &:last-of-type {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0) !important;
  }
`;

export { BoardEndBlock, BoardEndButton, BoardEndButtons, BoardEndContent, BoardEndLogo, BoardEndTitle };
